import { appConfig } from '../config';

export const isUserReadonly = (userRole: string): boolean => {
  if (userRole === 'operator') {
    return true;
  }
  return false;
};

export const isUserSuperUser = (userRole: string): boolean => {
  if (userRole === 'superuser') {
    return true;
  }
  return false;
};

export const isCloudPlatform = (): boolean => {
  // TODO - Remove localhost code later
  const platform = localStorage.getItem('platform');
  if (platform) {
    return platform === 'gcp';
  }
  return appConfig.platform === 'gcp';
};

export const isOnPremisePlatform = (): boolean => {
  // TODO - Remove localhost code later
  const platform = localStorage.getItem('platform');
  if (platform) {
    return platform === 'onPrem' || platform === 'onPremiseDevelopment';
  }
  return (
    appConfig.platform === 'onPrem' ||
    appConfig.platform === 'onPremiseDevelopment'
  );
};

export const isServerRolePrimary = (): boolean => {
  return appConfig.server.role === 'primary';
};

export const isServerRoleSecondary = (): boolean => {
  return appConfig.server.role === 'secondary';
};
