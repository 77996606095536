import { IconButton, styled } from '@mui/material';
import Select from '@mui/material/Select';

interface AllowAddProps {
  add_allowed?: boolean | string;
  delete_allowed?: boolean | string;
}

export const AAISelectComponent = styled(Select)<AllowAddProps>`
  .MuiSelect-icon {
    margin-right: ${(p): string =>
      p.add_allowed === 'true' && p.delete_allowed === 'true'
        ? '32px'
        : p.add_allowed === 'true'
        ? '40px'
        : '0'};
  }
  .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const AAIIconButton = styled(IconButton)`
  padding: 0;
`;