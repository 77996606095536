import { AlertColor, Link } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import { postAPI } from '../../utils/httputils';
import Alert from '../Alert';
import { useNavigate } from 'react-router-dom';

function EmailSettingsAlert({
  alertText,
  severity,
  closeHandler,
}: {
  alertText?: string;
  severity?: AlertColor;
  closeHandler?: Function;
}) {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { setTitleHeight } = useContext(GlobalContext);
  const [msg, setMsg] = useState<string>();
  const severityLevel = severity || 'warning';
  const [showMsg, setShowMsg] = useState(false);

  useEffect(() => {
    postAPI('config.email.settings.get', { orgID: auth.user.orgID }, (resp) => {
      if (!resp.error || resp.error === '') {
        const result = resp.data;
        if (!result.smtpHost) {
          showErrorMsg()
        }
      } else {
        showErrorMsg()
      }
    });
  }, [auth.user.orgID]);

  const handleLinkClick = () => {
    navigate('/system/notification-settings');
  };

  const showErrorMsg = () => {
    setMsg(alertText || '');
    setShowMsg(true);
  };

  return (
    <>
      {showMsg && (
        <Alert
          severity={severityLevel}
          sx={{ m: 1 }}
          onClose={() => {
            setShowMsg(false);
            setTitleHeight(170);
            if (closeHandler) {
              closeHandler();
            }
          }}
        >
          {t(msg)}
          <Trans
            t={t}
            i18nKey={'email_settings_alert'}
            components={{
              link2: (
                <Link
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={handleLinkClick}
                />
              ),
            }}
          />
        </Alert>
      )}
    </>
  );
}

export default EmailSettingsAlert;
