import {
  Button,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  useTheme,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import AAISelect from '../AAISelect';

export interface PageAction {
  callbackHandler: any;
  caption?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  variant?: 'text' | 'contained' | 'outlined';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  iconOnly?: boolean;
  shortCaption?: string;
  showForReadonlyUser?: boolean;
  type?: string;
  typeConfig?: any;
}

export default function PageAction(props: PageAction) {
  const { t }: { t: any } = useTranslation();
  const { mobileView } = useContext(GlobalContext);
  const readonly = useAuth().user.readOnly;
  const theme = useTheme();
  const showAction = () => {
    return !readonly || (readonly && props.showForReadonlyUser);
  };
  const [selectedVal, setSelectedVal] = useState<string>(
    props?.typeConfig?.initVal
  );

  const onSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedVal(event.target.value);
    props.callbackHandler(event.target.value);
  };

  const SelectComp = () => {
    return (
      <AAISelect
        id={`id_${props.typeConfig.name}_select`}
        labelId={`${props.typeConfig.name}_label`}
        name={props.typeConfig.name}
        value={selectedVal}
        label={t(props.typeConfig.name + '_dropdown')}
        onChange={onSelectChange}
        size="small"
        caption={t(props.typeConfig.name + '_dropdown')}
        sx={{
          width: mobileView ? '225px' : '300px',
          p: 0,
          color:
            theme.palette.mode === 'light'
              ? theme.colors.primary.main
              : 'primary',
          fontWeight: 'bold',
        }}
      >
        {props.typeConfig.options.map((opt) => (
          <MenuItem
            id={`id_opt_select_option_${opt.value}`}
            key={opt.label}
            value={opt.value}
          >
            {t(opt.label)}
          </MenuItem>
        ))}
      </AAISelect>
    );
  };
  return (
    <>
      {showAction() && (
        <>
          {props.iconOnly && !mobileView ? (
            <Tooltip title={t(props.caption)}>
              <IconButton
                sx={{
                  ml: 1,
                  border: '1px solid',
                  borderColor: 'primary.light',
                }}
                onClick={props.callbackHandler}
                color={props.color ? props.color : 'primary'}
                id={'id_page_button_' + props.caption}
              >
                <props.icon />
              </IconButton>
            </Tooltip>
          ) : mobileView ? (
            <>
              {props.icon ? (
                <Tooltip title={t(props.caption)}>
                  <IconButton
                    sx={{
                      ml: 1,
                      border: '1px solid',
                      borderColor: 'primary.light',
                    }}
                    size="small"
                    onClick={props.callbackHandler}
                    color={props.color ? props.color : 'primary'}
                    id={'id_page_button_' + props.caption}
                  >
                    <props.icon />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  {props.type === 'select' ? (
                    <SelectComp />
                  ) : (
                    <Button
                      sx={{
                        ml: 1,
                      }}
                      onClick={props.callbackHandler}
                      variant={props.variant ? props.variant : 'contained'}
                      color={props.color ? props.color : 'primary'}
                      id={'id_page_button_' + props.caption}
                      size="small"
                    >
                      {t(
                        props.shortCaption ? props.shortCaption : props.caption
                      )}
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {props.type === 'select' ? (
                <SelectComp />
              ) : (
                <Button
                  sx={{
                    ml: 1,
                  }}
                  onClick={props.callbackHandler}
                  variant={props.variant ? props.variant : 'contained'}
                  startIcon={<props.icon fontSize="small" />}
                  color={props.color ? props.color : 'primary'}
                  id={'id_page_button_' + props.caption}
                >
                  {t(props.caption)}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
