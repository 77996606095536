import validator from 'validator';

const validIssuerURLOptions = {
  protocols: ['https'],
  require_tld: true,
  require_protocol: true,
  require_host: true,
  require_port: false,
  require_valid_protocol: true,
  allow_underscores: false,
  host_whitelist: false,
  host_blacklist: false,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  allow_fragments: false,
  allow_query_components: true,
  disallow_auth: false,
  validate_length: true,
};

export const isValidIssuerURL = (url) => {
  if (validator.isURL(url, validIssuerURLOptions)) {
    return true;
  }
  return false;
};

export const isValidHttpUrl = (urlStr: string) => {
  let url: URL;
  try {
    url = new URL(urlStr);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isValidDomain = (domain) => {
  if (domain && validator.isFQDN(domain)) {
    return true;
  }
  return false;
};

export const isEmailValid = (email: string): boolean => {
  if (email && validator.isEmail(email, { allow_underscores: true })) {
    return true;
  }

  return false;
  // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // return regex.test(val);
};

export const isNumeric = (value: string) => {
  return /^\d+$/.test(value);
};

export const isHexadecimal = (value: string) => {
  const re = /^[0-9a-fA-F:\-]+$/;
  return re.test(value);
};

export const isIPV4Valid = (ipaddress: string): boolean => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
};

export const isIPV6Valid = (ipaddress: string): boolean => {
  if (
    /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
};

export const isValidIPv4Subnet = (input: string): boolean => {
  // Regular expression to match IPv4 subnet in CIDR notation
  const ipv4SubnetRegex = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/;

  if (!ipv4SubnetRegex.test(input)) {
    return false; // Doesn't match the expected format
  }

  // Split the input into octets and subnet mask
  const [ip, subnetMask] = input.split('/');

  // Check if each octet is a valid number between 0 and 255
  const octets = ip.split('.').map(Number);
  if (octets.some((octet) => isNaN(octet) || octet < 0 || octet > 255)) {
    return false; // Invalid octet
  }

  // Check if the subnet mask is a valid number between 0 and 32
  const subnetMaskValue = Number(subnetMask);
  if (isNaN(subnetMaskValue) || subnetMaskValue < 0 || subnetMaskValue > 32) {
    return false; // Invalid subnet mask
  }

  return true; // Passed all checks
};

export const isPostiveNumber = (value: string): boolean => {
  let regex = /^\d*[1-9]\d*$/;
  return regex.test(value);
};

export const validatePhoneNumber = (input: string): string => {
  var value = input.trim();
  if (value.length < 10) {
    return 'phone_err_min_check';
  }
  if (value.length > 15) {
    return 'phone_err_max_check';
  }
  if (!value.startsWith('+')) {
    return 'phone_err_starts_with_check';
  }

  return ''; // Passed all checks
};
export const testDomain = (email: string, domain: string): boolean => {
  // Validate the email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    throw false;
  }

  // Split the email to get the domain
  const emailDomain = email.split('@')[1];
  return emailDomain === domain;
};
