import CachedIcon from '@mui/icons-material/Cached';
import { Grid, IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from 'react-simple-captcha';
import AAITextField from '../AAITextField';

interface CaptchaProps {
  onChange: any;
  captchaColor?: string;
  captchaBgColor?: string;
  noOfChar?: number;
  btnTextColor?: string;
  textColor?: string;
  btnBorderRadius?: number;
  error?: string;
  captchaPlaceholder?: string;
}

const Captcha: FC<CaptchaProps> = ({
  captchaColor = '#000',
  captchaBgColor = '#4179BD',
  noOfChar = 6,
  btnTextColor = '#FFFFFF',
  textColor = '#000',
  btnBorderRadius = 1,
  onChange,
  error,
  captchaPlaceholder,
}) => {
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState({ value: '', error: error });

  const handleChange = (e) => {
    let val = e.target.value;
    setCaptcha({ value: val, error: '' });
    onChange(validateCaptcha(val, false));
  };

  const reloadCaptcha = () => {
    loadCaptchaEnginge(noOfChar, captchaBgColor, captchaColor);
    setCaptcha({ value: '', error: '' });
    onChange(validateCaptcha('', false));
  };

  useEffect(() => {
    loadCaptchaEnginge(noOfChar, captchaBgColor, captchaColor);
  }, [noOfChar, captchaBgColor, captchaColor, btnTextColor, btnBorderRadius]);

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent={'center'}
        spacing={1}
      >
        <Grid item ml={0.5} marginTop={'2px'}>
          <LoadCanvasTemplateNoReload />
        </Grid>
        <Grid item>
          {/* <Button
            variant="outlined"
            size="small"
            startIcon={<CachedIcon />}
            onClick={reloadCaptcha}
            sx={{
              borderRadius: btnBorderRadius,
              background: captchaBgColor,
              color: btnTextColor,
              '&:hover': {
                background: captchaBgColor,
              },
            }}
          ></Button> */}
          <IconButton
            id="id_button_reload"
            data-testid="id_test_button_reload"
            aria-label="toggle reload visibility"
            onClick={reloadCaptcha}
            size="small"
            sx={{
              color: captchaBgColor,
            }}
            edge="end"
          >
            <CachedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} py={1}>
          <AAITextField
            id="id_captcha_text"
            error={Boolean(captcha.error)}
            fullWidth
            variant="outlined"
            helperText={captcha.error}
            placeholder={t(captchaPlaceholder || 'enter_captcha')}
            name="captcha"
            onChange={handleChange}
            value={captcha.value}
            compact
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: captchaBgColor,
                },
                '&:hover fieldset': {
                  borderColor: `${captchaBgColor} !important`,
                },
                '&.Mui-focused fieldset': {
                  borderColor: captchaBgColor,
                },
              },
              '& .MuiInputBase-input': {
                color: textColor,
              },
              '& .MuiInputLabel-root': {
                color: textColor,
              },
              '& .MuiOutlinedInput-input': {
                '&::placeholder': {
                  color: textColor,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Captcha;
