import { Typography } from '@mui/material';
import React from 'react';
import validator from 'validator';

export const normalizeMac = (mac) => {
  return mac.replace(/\W/gi, '').toLowerCase();
};

export const normalizeOui = (oui) => {
  return oui.replace(/\:/gi, '').replace(/\-/gi, '').trim().toLowerCase();
};

export const validateMacOui = (macAuthAllowOUI) => {
  return macAuthAllowOUI.match(/^([0-9a-f]{1,2}[:-]*){2}([0-9a-f]{1,2})$/i);
};

export const getDisplayMac = (mac) => {
  try {
    // this code is written for onPrem (nads) as synthetic mac is getting received from backend
    if (isValidMACAddress(mac)) {
      mac = normalizeMac(mac);
      return mac.match(/.{1,2}/g).join(':');
    }
    return mac ? mac : '';
  } catch (e) {
    return mac ? mac : '';
  }
};

export const formatMacKeyColumn = (mac) => {
  return <Typography variant={'h5'}>{getDisplayMac(mac)}</Typography>;
};

export const formatIPKeyColumn = (ip) => {
  return <Typography variant={'h5'}>{getDisplayIp(ip)}</Typography>;
};

export const isValidMACAddress = (mac) => {
  if (mac) {
    mac = mac.trim();
    try {
      if (
        validator.isMACAddress(mac) ||
        validator.isMACAddress(mac, { no_separators: true })
      ) {
        return true;
      }
    } catch (e) {}
  }
  return false;
};

// List of allowed vendors
export const getVendorError = (val: String): String => {
  switch (val) {
    case 'Arista WiFi':
      return 'invalid_vendor_arista';

    case 'Arista Switch':
      return 'invalid_vendor_arista_switch';

    case 'Cisco Meraki':
      return 'invalid_vendor_cisco_meraki';

    case 'Arista':
    case 'Arista-Switch':
    case 'Aruba':
    case 'Cisco':
    case 'Generic':
    case 'Meraki':
    case 'Juniper':
      return null;
  }
  return 'invalid_error';
};

export const isSyntheticMAC = (mac) => {
  // Check if the first byte has the locally administered bit set
  const firstByte = parseInt(mac.substr(0, 2), 16);
  if ((firstByte & 0x02) === 0x02) {
    return true; // Locally administered MAC
  }

  return false; // Likely a real MAC address
};

export const getDisplayIp = (ip) => {
  if (ip === '0.0.0.0') {
    return '';
  }
  return ip ? ip : '';
};
