import {
  Alert,
  PasswordField,
  SuspenseLoader,
  appConfig as sharedAppConfig,
  formAPI,
  postAPI,
  showNotification,
  useRefMounted,
} from "@antara/shared";
import { Box, Button, Card, CircularProgress, Grid, TextField, Typography, useTheme } from "@mui/material";
import { Formik, setNestedObjectValues, yupToFormErrors } from "formik";
import { useSnackbar } from "notistack";
import qs from "qs";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { appConfig } from "src/config";
import * as Yup from "yup";

const LoginForm: FC = () => {
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [showLoader, setShowLoader] = useState(true);
  const [userType, setUserType] = useState("");
  const [networkID, setNetworkID] = useState(null);
  const [showProceed, setShowProceed] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [isSignup, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [apiError, setApiError] = useState({ error: false, errorKey: "" });
  const [termsOfUse, setTermsOfUse] = useState("");
  const [initialValues, setInitialValues] = useState({
    userID: "",
    password: "",
    submit: null,
    name: "",
    email: "",
    newPassword: "",
    otp: "",
  });
  const [usernameLabel, setUsernameLabel] = useState("");
  const [showOTPLogin, setShowOTPLogin] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [resendOTPClicked, setResendOTPClicked] = useState(false);
  const [resendLabel, setResendLabel] = useState(t("resend_otp"));
  const validateLoginForm = Yup.object().shape({
    userID: Yup.string().max(255),
    otp: Yup.string(),
    password: Yup.string()
      .when([], {
        is: () => userType === "local" || isPasswordReset,
        then: Yup.string().required(t("pass_reqd")),
      })
      .when([], {
        is: () => isSignup,
        then: Yup.string().min(8, t("pass_min_err")).max(150, t("pass_max_err")),
      }),
    name: Yup.string().when([], {
      is: () => isSignup,
      then: Yup.string().max(255).required(t("err_name_reqd")),
    }),
    email: Yup.string().when([], {
      is: () => isSignup,
      then: Yup.string().email(t("email_valid_err")).max(255).required(t("email_reqd")),
    }),
    newPassword: Yup.string().when([], {
      is: () => isPasswordReset,
      then: Yup.string().min(8, t("new_pass_min_err")).max(150, t("new_pass_max_err")).required(t("new_pass_reqd")),
    }),
  });

  const onboardLogin = (formData, setSubmitting, setStatus, type) => {
    if (appConfig.mock === "true") {
      localStorage.setItem("isAuthenticated", "y");
    }
    setShowLoader(true);
    formAPI("/onboard/" + id + "/login/" + type, formData, (resp) => {
      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
        if (resp.error === "") {
          if (resp.data === "captivePortal") {
            navigate("/?success=true");
          } else if (resp.data === "success" || userType === "local") {
            window.location.reload();
          } else {
            window.location.assign(resp.data);
          }
        } else {
          setShowLoader(false);
          let data = qs.parse(formData);
          if (data && data["loginName"]) {
            setInitialValues((prev) => ({ ...prev, userID: data["loginName"].toString() }));
          }
          setApiError({ error: true, errorKey: "login_" + resp.error });
        }
      }
    });
  };

  const handleSubmitForm = (values, { setSubmitting, setStatus, setFieldError }) => {
    let formData = qs.stringify({ loginName: values["userID"].trim(), password: values["password"].trim(), orgID: id });
    if (!isSignup) {
      if (!values["userID"].trim()) {
        setFieldError("userID", t("userID_email_reqd"));
        setSubmitting(false);
      } else {
        onboardLogin(formData, setSubmitting, setStatus, "local");
      }
    } else {
      let payload = {
        orgID: id,
        name: values["name"].trim(),
        email: values["email"].trim(),
        password: values["password"].trim(),
      };

      if (networkID) {
        payload["networkID"] = networkID;
      }

      postAPI("prelogin.local.user.add", payload, (resp) => {
        formData = qs.stringify({ password: values["password"].trim(), loginName: values["email"].trim() });
        if (resp.error === "") {
          onboardLogin(formData, setSubmitting, setStatus, "local");
        } else {
          setSubmitting(false);
          setApiError({ error: true, errorKey: "user_signup_failed_" + resp.error });
        }
      });
    }
  };

  const onProceed = async (values, { setSubmitting, setStatus, setTouched, setFieldError }) => {
    if (!values["userID"].trim()) {
      setFieldError("userID", t("userID_email_reqd"));
    } else {
      let errors = {};
      try {
        await validateLoginForm.validate(values, { abortEarly: false });
      } catch (err) {
        errors = yupToFormErrors(err);
        setTouched(setNestedObjectValues(errors, true));
      }
      if (Object.keys(errors).length === 0) {
        let formData = qs.stringify({ loginName: values["userID"].trim(), orgID: id });
        postAPI("prelogin.provider.get", { loginName: values["userID"].trim(), orgID: id }, (resp) => {
          if (resp.error === "") {
            if (resp.data.provider === "external") {
              if (resp.data.allowOTPLogin) {
                setShowOTPLogin(true);
              } else {
                onboardLogin(formData, setSubmitting, setStatus, "oidc");
              }
            } else {
              setShowProceed(false);
              setUserType(resp.data.provider);
            }
          } else {
            setApiError({ error: true, errorKey: "login_user_not_found_" + resp.error });
          }
        });
      }
    }
  };

  const onSSOLogin = async (values, { setSubmitting, setStatus }) => {
    let formData = qs.stringify({ loginName: values["userID"].trim(), orgID: id });
    onboardLogin(formData, setSubmitting, setStatus, "oidc");
  };

  const generateOTP = (values, resend = false) => {
    let formData = qs.stringify({ loginName: values["userID"].trim(), orgID: id });
    formAPI("/onboard/" + id + "/generateOTP", formData, (resp) => {
      if (resp.error === "") {
        setResendOTPClicked(true);
        setShowOTPLogin(false);
        setShowVerifyOTP(true);
        if (resend) {
          showNotification(enqueueSnackbar, t("resend_otp_success"), "success");
        }
      } else {
        setApiError({ error: true, errorKey: "otp_send_error" });
      }
    });
  };

  const onOTPLoginClicked = async (values, { setSubmitting, setStatus }) => {
    let formData = qs.stringify({ loginName: values["userID"].trim(), otp: values["otp"].trim(), orgID: id });
    onboardLogin(formData, setSubmitting, setStatus, "otp");
  };

  const onPasswordReset = async (values, { setTouched }) => {
    let errors = {};
    try {
      await validateLoginForm.validate(values, { abortEarly: false });
    } catch (err) {
      errors = yupToFormErrors(err);
      setTouched(setNestedObjectValues(errors, true));
    }

    if (Object.keys(errors).length === 0) {
      postAPI(
        "me.user.password.update",
        { userID: values["userID"].trim(), orgID: id, oldPassword: values["password"], newPassword: values["newPassword"] },
        (resp) => {
          if (resp.error === "") {
            getEntry();
          } else {
            setApiError({ error: true, errorKey: "password_reset_" + resp.error });
          }
        }
      );
    }
  };

  const handleUserIDChange = () => {
    setShowOTPLogin(false);
    setUserType("");
    setShowProceed(true);
    if (apiError.error) {
      setApiError({ error: false, errorKey: "" });
    }
  };

  const getEntry = useCallback(async () => {
    postAPI("me.client.onboard.info", {}, (resp) => {
      if (resp.error !== "") {
        navigate("/" + id + "/clients");
      } else {
        if (!resp.data.mac || resp.data.mac === "") {
          navigate("/" + id + "/passphrase/view");
        } else {
          navigate("/" + id + "/register");
        }
      }
    });
  }, [id, navigate]);

  const getUserInfo = useCallback(() => {
    postAPI("me.user.info", {}, (resp) => {
      if (resp.error === "") {
        if (resp.data.passwordChangeRequired) {
          setIsPasswordReset(true);
          setShowLoader(false);
        } else {
          getEntry();
        }
      } else {
        postAPI("prelogin.network.onboard.info", {}, (resp) => {
          if (resp.error === "") {
            setNetworkID(resp.data.networkID);
            if (resp.data.selfRegistrationAllowed) {
              setShowSignup(true);
            }
          } else {
            if (resp.error !== "invalid_input_networkID") {
              setApiError({ error: true, errorKey: "network_fetch_" + resp.error });
            }
          }
          setShowLoader(false);
        });
      }
    });
  }, [getEntry]);

  useEffect(() => {
    const theme = sharedAppConfig.onboardTheme;
    if (theme) {
      if (theme.termsOfUse) {
        setTermsOfUse(theme.termsOfUse);
      }
      setUsernameLabel(theme.usernameLabel);
    }
    getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    let interval: any;
    if (resendOTPClicked) {
      let count = 30;
      interval = setInterval(function () {
        if (document.visibilityState === "visible" && count > 0) {
          setResendLabel(t("resend_otp_seconds")); //, { count: count }));
          count--;
        }
        if (count === 0) {
          clearInterval(interval);
          setResendOTPClicked(false);
          setResendLabel(t("resend_otp"));
        }
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [resendOTPClicked, t]);

  return showLoader ? (
    <SuspenseLoader />
  ) : (
    <Card
      sx={{
        mt: 5,
        px: 4,
        pt: 5,
        pb: 3,
        background: sharedAppConfig?.onboardTheme?.loginFormColor || "#FFFFFF",
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            mb: 1,
          }}
        >
          {showVerifyOTP ? t("verify_otp") : isSignup ? t("signup") : isPasswordReset ? t("update_password") : t("signin")}
        </Typography>
      </Box>
      {apiError.error && (
        <Alert severity="error">
          <Typography variant="body1" gutterBottom id="id_error_signin">
            {t(apiError.errorKey)}
          </Typography>
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={validateLoginForm} onSubmit={handleSubmitForm} enableReinitialize>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setStatus,
          setSubmitting,
          setTouched,
          setFieldValue,
          setFieldError,
        }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit}>
            {!isSignup && !isPasswordReset && !showVerifyOTP && (
              <TextField
                id="id_userID_text"
                error={Boolean(touched.userID && errors.userID)}
                fullWidth
                margin="normal"
                helperText={touched.userID && errors.userID}
                label={t(usernameLabel || "userID_or_email")}
                name="userID"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleUserIDChange();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onProceed(values, { setSubmitting, setStatus, setTouched, setFieldError });
                    e.preventDefault();
                  }
                }}
                value={values.userID}
                variant="outlined"
                autoFocus
              />
            )}

            {isSignup && (
              <TextField
                id="id_name_text"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                margin="normal"
                autoFocus
                helperText={touched.name && errors.name}
                label={t("Name")}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            )}

            {isSignup && (
              <TextField
                id="id_email_text"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                margin="normal"
                helperText={touched.email && errors.email}
                label={t("Email")}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            )}

            {(userType === "local" || isSignup || isPasswordReset) && (
              <Grid sx={{ pt: 2, pb: 1 }}>
                <PasswordField
                  id="password"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  label={isPasswordReset ? t("old_password") : t("Password")}
                  name="password"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  value={values.password}
                  autoFocus={!isSignup}
                />
              </Grid>
            )}

            {isPasswordReset && (
              <Grid sx={{ pt: 2 }}>
                <PasswordField
                  id="newPassword"
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                  label={t("new_password")}
                  name="newPassword"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  value={values.newPassword}
                />
              </Grid>
            )}

            {!isPasswordReset && (
              <>
                {termsOfUse.startsWith("http") ? (
                  <Typography pt={1}>
                    {t("terms_of_use_prefix")}&nbsp;
                    <a target="_blank" href={termsOfUse} rel="noreferrer">
                      {t("terms_of_use")}
                    </a>
                  </Typography>
                ) : (
                  <Typography pt={1}>{termsOfUse}</Typography>
                )}
              </>
            )}

            {showProceed && !isSignup && !isPasswordReset && !showOTPLogin && !showVerifyOTP && (
              <Button
                sx={{
                  mt: 2,
                }}
                id="id_button_proceed"
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => onProceed(values, { setSubmitting, setStatus, setTouched, setFieldError })}
              >
                {t("Proceed")}
              </Button>
            )}

            {showOTPLogin && (
              <>
                <Button
                  sx={{
                    mt: 2,
                    mr: 1,
                  }}
                  id="id_button_otp_login"
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => generateOTP(values)}
                >
                  {t("otp_login")}
                </Button>
                <Button
                  sx={{
                    mt: 2,
                  }}
                  id="id_button_sso_login"
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => onSSOLogin(values, { setSubmitting, setStatus })}
                >
                  {t("sso_login")}
                </Button>
              </>
            )}

            {showVerifyOTP && (
              <>
                <Box>
                  <Typography
                    sx={{
                      pb: 0,
                      pt: 0,
                    }}
                    variant="subtitle2"
                  >
                    <Alert severity="info">{t("otp_sent_info")}</Alert>
                  </Typography>
                </Box>
                <TextField
                  id="id_otp_text"
                  error={Boolean(touched.otp && errors.otp)}
                  fullWidth
                  margin="normal"
                  helperText={touched.otp && errors.otp}
                  label={t("OTP")}
                  placeholder={t("enter_otp")}
                  name="otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onOTPLoginClicked(values, { setSubmitting, setStatus });
                      e.preventDefault();
                    }
                  }}
                  value={values.otp}
                  variant="outlined"
                  autoFocus
                />

                <Button
                  sx={{
                    mt: 2,
                    mr: 1,
                  }}
                  id="id_button_otp_login"
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => onOTPLoginClicked(values, { setSubmitting, setStatus })}
                >
                  {t("submit_btn")}
                </Button>

                <Grid container justifyContent="center">
                  <Typography
                    sx={{
                      mt: 2,
                    }}
                    id="id_button_sso_login"
                    style={{
                      textDecoration: "underline",
                      cursor: resendOTPClicked ? "default" : "pointer",
                      color: resendOTPClicked ? theme.palette.text.disabled : theme.colors.primary.main,
                    }}
                    onClick={() => generateOTP(values, true)}
                  >
                    {resendLabel}
                  </Typography>
                </Grid>
              </>
            )}

            {isPasswordReset && (
              <Button
                sx={{
                  mt: 2,
                }}
                id="id_button_updatePassword"
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => onPasswordReset(values, { setTouched })}
              >
                {t("update_password")}
              </Button>
            )}

            {(userType === "local" || isSignup) && (
              <>
                <Button
                  sx={{
                    mt: 2,
                  }}
                  id="id_button_signin"
                  color="primary"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {isSignup ? t("signup") : t("signin")}
                </Button>
              </>
            )}

            {showSignup && !isSignup && !showOTPLogin && !showVerifyOTP && (
              <Button
                sx={{
                  mt: 2,
                }}
                id="id_button_signup"
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => {
                  setIsSignUp(true);
                  setApiError({ error: false, errorKey: "" });
                  setFieldValue("password", "");
                }}
              >
                {t("signup")}
              </Button>
            )}
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default LoginForm;
