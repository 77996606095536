export const appConfig = {
  mock: process.env.REACT_APP_MOCK,
  basename: process.env.REACT_APP_BASENAME,
  appname: process.env.REACT_APP_NAME,
  unitTest: process.env.REACT_APP_UT === 'true',
  enableLocale: process.env.REACT_APP_I18N === 'true',
  deviceAdminAllowed: false,
  avaConsentAccept: false,
  eduroamEnabled: false,
  skipError: process.env.REACT_APP_SKIP_ERROR === 'true',
  platform: 'gcp',
  server: { role: 'primary' },
  // placeholder to save onboard theme json
  onboardTheme: undefined,
};

export const redirectURLs = [
  {
    url: 'monitoring/session',
    backendAPI: 'session.get',
    redirectUrl: 'monitoring/sessions',
    resourceName: 'Session',
  },
  {
    url: 'access/segments',
    backendAPI: 'config.segment.get',
    resourceName: 'Segment',
  },
  {
    url: 'access/networks',
    backendAPI: 'config.network.get',
    resourceName: 'Network',
  },
  {
    url: 'access/acls',
    backendAPI: 'config.acl.get',
    resourceName: 'ACL',
  },
  {
    url: 'identity/iam',
    backendAPI: 'config.idp.get',
    resourceName: 'Identity Provider',
  },
  {
    url: 'identity/user-groups',
    backendAPI: 'config.userGroup.get',
    resourceName: 'User Group',
  },
  {
    url: 'identity/users',
    backendAPI: 'identity.user.get',
    resourceName: 'User',
    skip: '/new',
  },
  {
    url: 'identity/clients',
    backendAPI: 'identity.client.get',
    resourceName: 'Client',
  },
  {
    url: 'identity/client-groups',
    backendAPI: 'config.clientGroup.get',
    resourceName: 'Client Group',
  },
  {
    url: 'config/access-devices',
    backendAPI: 'config.nad.get',
    resourceName: 'Access Device',
  },
  {
    url: 'config/access-device-groups',
    backendAPI: 'config.nadGroup.get',
    resourceName: 'Access Device Group',
  },
  {
    url: 'config/certs/trusted',
    backendAPI: 'config.cert.ca.get',
    resourceName: 'Trusted Certificate',
  },
  {
    url: 'config/cloud-gateways',
    backendAPI: 'config.acgDevice.list',
    resourceName: 'Cloud Gateways',
  },
  {
    url: 'config/administration',
    backendAPI: 'config.deviceadmin.policy.list',
    resourceName: 'Device Administration',
  },
  {
    url: 'access/tacacs',
    backendAPI: 'config.tacacs.profile.list',
    resourceName: 'TACACS+ Profiles',
  },
  {
    url: 'identity/guests/portals',
    backendAPI: 'config.portal.list',
    resourceName: 'Portal Settings',
  },
];
