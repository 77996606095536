export const TRANSLATIONS_ZH = {
  onboard: "你好Self-Service Portal",
  Navigation: "管 Navigation",
  Languages: "你好 - Languages",
  signout: "你好 - Sign Out",
  copy_org_id: "你好 Copy Organization ID",
  Browse: "你好 - Browse",
  Confirm: "你好 - Confirm",
  Scan: "你好 - Scan",
  table_view: "你好 - Table View",
  card_view: "你好 - Card View",

  // Errors
  invalid_input_clientMAC: "你好 - Enter the correct Client MAC address.",
  onboard_not_allowed_for_user_group: "你好 - You are not authorized to onboard.",
  login_captive_portal_not_allowed_on_network: "你好 - User is not allowed on this network",
  captive_portal_not_allowed_on_network: "你好 - User is not allowed on this network",
  user_domain_does_not_match_org_domain: "你好 - The user domain does not match the organization's domain",
  client_quarantined: "你好 - This device is quarantined due to non-compliance with the organization's policy",
  invalid_jwt_claim_attr_value: "你好 - Another user has logged in from this browser.",
  login_undefined: "你好 - Login failed, invalid user or password.",
  invalid_input_idToken: "你好 - SSO login failed.",
  app_not_ready: "你好 - The application is not ready. Retry after some time.",

  // Success
  login_success: "你好 - You have successfully logged in!",

  // Signin
  signin: "你好 - Sign In",
  signup: "你好 - Sign Up",
  userID_or_email: "你好 - User ID or Email",
  Name: "你好 - Name",
  Email: "你好 - Email",
  Password: "你好 - Password",
  update_password: "你好 - Update Password",
  old_password: "你好 - Old Password",
  new_password: "你好 - New Password",
  userID_email_reqd: "你好 - UserID or Email is required",
  pass_reqd: "你好 - Password is required",
  pass_min_err: "你好 - Password must be at least 8 characters",
  pass_max_err: "你好 - Password cannot have more than 150 characters",
  email_valid_err: "你好 - Provide a valid email address",
  email_reqd: "你好 - Email is required",
  new_pass_min_err: "你好 - The new password must be at least 8 characters",
  new_pass_max_err: "你好 - The new password cannot have more than 150 characters.",
  pass_change_success: "你好 - Password changed successfully",
  change_pass: "你好 - Change Password",
  old_pass_reqd: "你好 - Old password is required",
  new_pass_reqd: "你好 - New password is required",
  login_user_password_mismatch: "你好 - Invalid user or password",
  login_user_not_found_idp_lookup_failed: "你好 - Invalid user or password",
  login_user_not_found_login_name_is_not_email: "你好 - Invalid user or password",
  login_internal_error: "你好 - Login failed, retry.",
  login_user_not_enabled: "你好 - You are not allowed to signin. Contact your administrator for further information.",
  contact_admin: "你好 - Contact your administrator for details.",
  user_signup_failed_internal_error: "你好 - User sign-in failed, retry.",
  user_signup_failed_user_already_exists: "你好 - User Sign-in failed; the user already exists.",
  login_user_not_found_internal_error: "你好 - Login failed, invalid user or password. Retry.",
  login_user_not_found_invalid_user_name_or_password: "你好 - Login failed, invalid user or password.",
  login_invalid_user_name_or_password: "你好 - Login failed, invalid user or password.",
  password_reset_internal_error: "你好 - Password reset failed; retry.",
  network_fetch_internal_error: "你好 - Fetching Network failed; retry.",
  network_fetch_network_not_found: "你好 - Network not found.",
  network_fetch_object_not_found: "你好 - Fetching network failed; retry.",
  network_fetch_onboard_not_allowed_on_network: "你好 - Onboarding of users is not permitted on this network.",
  network_fetch_invalid_prelogin_session: "你好 - Invalid prelogin session.",

  password_reset_user_new_password_cannot_be_same_as_old_password: "你好 - New password cannot be same as old password.",
  password_reset_user_incorrect_old_password: "你好 - Incorrect old password,",
  terms_of_use_prefix: "你好 - By signing in, you accept the",
  terms_of_use: "你好 - Terms of Use",
  license_expired_msg: "你好 - Access to this portal is not available currently.",

  // Register
  register_client: "你好 - Register Client",
  register_device: "你好 - Register Device",
  mac_address: "你好 - MAC Address",
  Description: "你好 - Description",
  Register: "你好 - Register",
  Passphrase: "你好 - Passphrase",
  Copy: "你好 - Copy",
  Copied: "你好 - Copied",
  Continue: "你好 - Continue",
  Download: "你好 - Download",
  launch_app: "你好 - Launch App",
  network_ssid: "你好 - Network SSID",
  Proceed: "你好 - Proceed",
  Done: "你好 - Done",
  register_step1_header: "你好 - Provide the following details to register your client device.",
  register_step2_header: "你好 - Your client is registered with the following passphrase. Configure your client with this passphrase.",
  register_step3_header: "你好 - Follow the steps to connect your client device",
  register_step3_help1: "你好 - 1. Click the <strong>Proceed</strong> button. You will be disconnected from the network.",
  register_step3_help2: "你好 - 2. Click <strong>{{ssid}}</strong> in Wi-Fi networks list to connect again.",
  register_step3_help3: "你好 - 3. When prompted, enter the new passphrase you copied.",
  register_step3_help4: "你好 - 4. Alternatively, you can also forget or delete <strong>{{ssid}}</strong> from Wi-Fi networks list first.",
  register_step3_help5: "你好 - 5. After deleting, reconnect to <strong>{{ssid}}</strong> with the new passphrase.",
  register_email_text: "你好 - You will receive an email with the new passphrase.",
  qr_code_title: "你好 - Scan this code to connect your clients",
  register_step2_cert_header: "你好 - Your client is registered. To connect your client:",
  register_step2_cert_help1: "你好 - 1. Click the <strong>Download</strong> button to proceed.",
  register_step2_cert_help2: "你好 - 2. A network profile is downloaded to your device.",
  register_step2_cert_help3: "你好 - 3. Go to the <strong>Settings</strong> application.",
  register_step2_cert_help4: "你好 - 4. Click <strong>Profile Downloaded</strong>",
  register_step2_cert_help5: "你好 - 5. Click <strong>Install</strong> to install the downloaded profile.",
  register_step2_cert_help6: "你好 - 6. Now you can connect to the secure network.",
  register_step2_cert_help1_android: "你好 - 1. Click <strong>Launch App</strong> button to proceed.",
  register_step2_cert_help2_android: "你好 - 2. If prompted, install Android application and launch it.",
  register_step2_cert_help3_android: "你好 - 3. Follow the instructions in the application to connect.",
  register_step2_cert_help1_windows: "你好 - 1. Click the <strong>Download</strong> button to proceed.",
  register_step2_cert_help2_windows: "你好 - 2. An executable file will be downloaded to your system.",
  register_step2_cert_help3_windows: "你好 - 3. Double-click the file to launch the AGNI Onboard app.",
  register_step2_cert_help4_windows: "你好 - 4. This app configures the device to connect to a secure network.",
  register_step2_cert_help5_windows: "你好 - 5. When prompted, click <strong>Yes</strong> to install the certificates.",
  register_step2_cert_help6_windows: "你好 - 6. Now you can connect to the secure network.",
  register_step2_cert_help1_linux: "你好 - 1. Click the <strong>Download</strong> button to proceed.",
  register_step2_cert_help2_linux: "你好 - 2. An executable script file will be downloaded to your system.",
  register_step2_cert_help3_linux: "你好 - 3. Run the script to configure the device and connect to the secure network.",
  register_step2_cert_help4_linux: "你好 - 4. This app will configure the device to connect to the secure network.",
  register_step2_cert_help1_chromeos: "你好 - 1. Install <link1><strong>CloudVision AGNI</strong></link1> on your Chromebook.",
  register_step2_cert_help2_chromeos: "你好 - 2. After app is installated, Go to the bottom right, select the time.",
  register_step2_cert_help3_chromeos: "你好 - 3. Select Settings ⚙ ",
  register_step2_cert_help4_chromeos:
    "你好 - 4. In the <strong>“Apps”</strong> section, Select <strong>Manage your apps</strong> > CloudVision AGNI",
  register_step2_cert_help5_chromeos:
    "你好 - 5. Under <strong>Opening Supported Links </strong>, Select <strong>Open in CloudVision AGNI</strong>.",
  register_step2_cert_help6_chromeos: "你好 - 6. Click <strong>Launch App</strong> button to proceed.",

  client_owner_mismatch: "你好 - Client already registered.",
  client_onboard_err_empty_org_id: "Onboarding failed, organization does not exist.",
  client_onboard_err_empty_client_mac: "你好 - Enter client MAC address.",
  client_onboard_err_empty_email: "你好 - Enter email address.",
  client_onboard_err_oneof_userid_or_email_required: "Enter email address.",
  client_onboard_err_user_does_not_exist: "Onboarding failed; user does not exist.",
  client_onboard_err_duplicate_client: "你好 - Duplicate client.",
  client_onboard_err_user_client_mismatch: "你好 - Onboarding failed; client does not belong to user.",
  client_onboard_err_internal_error: "你好 - Onboarding failed; retry.",
  client_onboard_err_invalid_input_mac: "你好 - Enter MAC address.",
  client_onboard_err_invalid_input_orgID: "你好 - Customer account does not exist.",
  client_onboard_err_upsk_max_personal_clients_exceeded: "你好 - You have exceeded the maximum number of allowed UPSK personal clients.",
  client_onboard_err_upsk_max_personal_clients_reached: "你好 - You have reached the maximum number of allowed UPSK personal clients.",
  client_onboard_err_eap_max_personal_clients_exceeded: "你好 - You have exceeded the maximum number of allowed EAP-TLS clients.",
  client_onboard_err_eap_max_personal_clients_reached: "你好 - You have reached the maximum number of allowed EAP-TLS clients.",
  client_onboard_err_invalid_input_networkID: "你好 - Add failed; at least one UPSK network with Onboarding enabled must be configured.",
  client_disconnect_err_empty_org_id: "你好 - Disconnect failed, the organization does not exist.",
  client_disconnect_err_empty_client_id: "你好 - Disconnect failed, the client does not exist.",
  client_disconnect_err_empty_user_id: "你好 - Disconnect failed, the user does not exist.",
  client_disconnect_err_user_client_mismatch: "你好 - Disconnect failed; the client does not belong to the user.",
  client_disconnect_err_internal_error: "你好 - Disconnect failed; retry.",
  client_onboard_err_object_already_exists: "你好 - Client already exists.",
  client_onboard_success: "你好 - Client added successfully.",

  // clients
  manage_clients: "你好 - Manage Clients",
  manage_clients_desc: "你好 - Manage the list of clients",
  client_delete_success: "你好 - Client deleted successfully",
  update_client: "你好 - Update Client",
  Status: "你好 - Status",
  Update: "你好 - Update",
  Clients: "你好 - Clients",
  Count: "你好 - Count",
  Enabled: "你好 - Enabled",
  Disabled: "你好 - Disabled",

  client_get_err: "你好 - Error fetching client details",
  client_desc_update: "你好 - Provide the following details to update the selected client.",
  client_delete_err_internal_error: "你好 - Delete failed; retry.",
  client_delete_err_empty_user_id: "你好 - Delete failed; the user does not exist.",
  client_delete_err_client_does_not_exist: "Delete failed; client does not exist.",
  client_delete_err_client_id: "你好 - Delete failed; client does not exist.",
  client_delete_err_empty_org_id: "你好 - Delete failed; organization does not exist.",
  client_list_err_empty_org_id: "你好 - Fetching client details failed; the organization does not exist.",
  client_list_err_empty_user_id: "你好 - Fetching client details failed; the user does not exist.",
  client_list_err_client_does_not_exist: "Fetching client details failed; the client does not exist.",
  client_list_err_internal_error: "你好 - Fetching client details failed; retry.",
  client_update_err_empty_org_id: "你好 - The update failed; the organization does not exist.",
  client_update_err_empty_client_id: "你好 - The update failed; the client does not exist.",
  client_update_err_invalid_client_status: "Update failed; client status is invalid.",
  client_update_err_empty_user_id: "你好 - The update failed; the user does not exist.",
  client_update_err_internal_error: "你好 - Update client failed; retry.",
  client_update_err_client_does_not_exist: "你好 - The update failed; and the client does not exist.",
  client_update_err_invalid_input_orgID: "你好 - Customer account does not exist.",
  client_update_err_invalid_input_clientID: "你好 - Update failed, client does not exist.",
  client_update_err_invalid_input_status: "你好 - Update failed; the client status is invalid.",
  client_err_description_reqd: "你好 - Enter the description.",
  clients_delete_msg_mac: "你好 - Are you sure you want to delete Client with MAC address '{{entity}}'?",
  clients_delete_msg_no_mac: "你好 - Are you sure you want to delete Client with description '{{entity}}'?",
  clients_search_desc: "你好 - Search by MAC address or description...",
  status: "你好 - Status",
  Delete: "你好 - Delete",
  owner_user: "你好 - Owner (User)",
  update_time: "你好 - Update Time",
  client_update_success: "你好 - Client updated successfully",
  client_passphrase_expired: "你好 - Your personal passphrase has expired. Go <link1>here</link1> to regenerate.",

  // view passphrase
  clients_link: "你好 - Click <link1>here</link1> to manage clients.",
  passphrase_view_subtitle: "你好 - This is your personal passphrase. Use this passphrase to connect your clients to this network.",
  personal_passphrase: "你好 - Personal Passphrase",
  wireless_network: "你好 - Wireless Network",

  // passphrase
  wifi_passphrase: "你好 - Wi-Fi Passphrase",
  view_wifi_passphrase: "你好 - View Wi-Fi passphrase",
  client_group: "你好 - Client Group",
  passphrase_updated: "你好 - The passphrase is updated.",
  passphrase_subtitle: "你好 - This is your passphrase. Click Regenerate to create a new passphrase if needed.",
  passphrase_info: "你好 - The new passphrase is generated. Click Update to use this passphrase for any future registrations.",
  group_passphrase_subtitle: "你好 - This passphrase is for all clients in the selected Client Group.",
  Regenerate: "你好 - Regenerate",
  Cancel: "你好 - Cancel",
  passphrase_err_empty_org_id: "Generating passphrase failed; the organization does not exist.",
  passphrase_err_empty_email: "你好 - Generating passphrase failed; empty email address.",
  passphrase_err_empty_user_id: "Generating passphrase failed; user does not exist.",
  passphrase_err_empty_psk_passphrase: "你好 - Update failed; empty passphrase",
  passphrase_err_user_does_not_exist: "你好 - Update failed; user does not exist.",
  passphrase_err_internal_error: "你好 - Update failed; retry.",
  passphrase_err_invalid_input_pskPassphrase: "你好 - Update failed; empty passphrase",
  passphrase_err_invalid_input_orgID: "你好 - The customer account does not exist.",
  passphrase_generate_err_empty_user_id: "你好 - Generating passphrase failed; the user does not exist.",
  passphrase_generate_err_internal_error: "你好 - Generating a new passphrase failed; retry.",
  passphrase_qr_code_title: "你好 - Scan this code to connect your clients",
  user_network_err_internal_error: "你好 - Fetching network list failed.",
  passphrase_expired_only: "你好 - Your passphrase has expired.",
  passphrase_expired: "你好 - Your passphrase has expired. Click Regenerate to create a new passphrase.",
  passphrase_expire_on_1: "你好 - Your passphrase expires on ",
  passphrase_expire_on_2: "你好 - Click on Regenerate to create a new passphrase.",
  passphrase_expired_regenerate: "你好 - Go <link1>here</link1> to regenerate.",
  passphrase_expired_on_1: "你好 - Your passphrase expired on ",
  passphrase_expired_on_2: "你好 - Click Regenerate to create a new passphrase.",

  // add client
  register_cliend_desc: "你好 - Register a new client for network access",
  allowed_wireless_networks: "你好 - Allowed Wireless Networks",
  scan_barcode: "你好 - Scan Barcode",
  not_available: "你好 - Not Available",
  error_scan_laptopview: "你好 - Error scanning file: Upload a valid and clear image with just the bar code.",
  error_scan_mobileview: "你好 - Error scanning file: Zoom and capture an image with just the bar code.",
  alert_info_scan_mobileview: "你好 - Zoom in and capture an image with just the bar code.",
  alert_info_scan_laptopview: "你好 - Choose the bar code image file",
  error_invalid_image: "你好 - Upload a valid image file in jpg | jpeg | png | gif format.",
  register_subtitle_success:
    "你好 - For wireless access, your client is registered using the following passphrase: Configure your client with this passphrase.",
  wired_register_subtitle_success: "你好 - Your client is registered for wired access.",
  register_wired_msg: "你好 - This client can also connect to wired networks.",
  register_wired_only_msg: "你好 - This client can connect to wired networks only.",
  register_new_client: "你好 - Provide the following details to register a new client",
  mac_reqd: "你好 - Enter MAC address",
  mac_invalid: "你好 - Invalid MAC address",
  mac_placeholder: "你好 - Example: 00:2a:5e:00:53:af",
  description_reqd: "你好 - Enter the description.",
  view_clients: "你好 - View Clients",
  client_onboard_err_user_cannot_manage_client_group: "你好 - This user cannot manage client group.",
  client_onboard_err_client_group_not_allowed_to_access_network: "你好 - This client group is not allowed to access the selected network.",
  clientgroup_network_err_user_cannot_manage_client_group: "你好 - This user cannot manage client group.",
  clientgroup_network_err_internal_error: "你好 - Fetching network list failed.",
  clientgroup_list_err_internal_error: "你好 - Fetching client groups failed.",

  // signout
  signout_success: "你好 - You have successfully signed out.",
  signin_again: "你好 - Click <link1>here</link1> to sign in again.",

  internal_error: "你好 - Unable to serve the request, try again later.",

  // Device Administration
  // Device Administration -> Devices
  device_admin: "你好 - Device Administration",
  Devices: "你好 - Devices",
  Setup: "你好 - Setup",
  apple_mac: "你好 - Apple MacBook",
  msft_win: "你好 - Microsoft Windows",
  devices_subheading: "你好 - Manage the list of devices",
  Add: "你好 - Add",
  Back: "你好 - Back",
  Connect: "你好 - Connect",
  Host: "你好 - Host",
  Port: "你好 - Port",
  id_devices_search_desc: "你好 - Search by name or host...",
  id_devices_delete_msg: "你好 - Are you sure you want to delete '{{entity}}' device ?",
  update_device: "你好 - Update Device",
  add_device: "你好 - Add Device",
  connect_device: "你好 - Connect Device",
  connect_device_ssh: "你好 - Connect Device to SSH Session",
  add_device_subheading: "你好 - Provide the following details to add a new device.",
  update_device_subheading: "你好 - Provide the following details to update the selected device.",
  max_row_select: "你好 - You can delete up to {{count}} rows at a time.",
  delete_all: "你好 - Delete All",
  bulk_actions: "你好 - Bulk Actions",
  id_devices_clients_bulk_delete_msg: "你好 - Are you sure you want to delete the selected devices?",
  id_devices_bulk_delete_msg_other: "你好 - Are you sure you want to delete {{count}} devices?",
  id_devices_bulk_delete_all_msg: "你好 - There is only one device. Are you sure you want to delete it?",
  id_devices_bulk_delete_all_msg_other: "你好 - There are {{count}} devices. Are you sure you want to delete all of them?",
  id_devices_bulk_delete_filtered_msg: "你好 - One device matches the current filter applied. Are you sure you want to delete it?",
  id_devices_bulk_delete_filtered_msg_other:
    "你好 - {{count}} devices match the current filter applied. Are you sure you want to delete all of them?",
  id_devices_bulk_delete_success_msg: "你好 - One device is deleted successfully",
  id_devices_bulk_delete_success_msg_other:
    "你好 - {{count}} devices are deleted successfully. Are you sure you want to delete this device?",
  id_devices_bulk_delete_msg: "你好 - Are you sure want to delete this device?",
  id_devices_bulk_delete_err: "你好 - Unable to delete the selected client",
  id_devices_bulk_delete_err_other: "你好 - Unable to delete {{errCount}} out of {{count}} clients",
  id_devices_bulk_delete_err_first_ten: "你好 - {{count}} clients are not deleted as they are active. Displaying the first 10 below:",
  id_devices_bulk_delete_internal_err: "你好 - {{count}} clients are not deleted due to an internal error.",

  // bulk
  id_devices_bulk_selected_filtered_msg: "管 One device that match current filter is selected.",
  id_devices_bulk_selected_filtered_msg_other: "管 {{count}} devices that match current filter are selected.",
  id_devices_bulk_selected_all_filtered_msg: "管 All {{count}} devices that match current filter are selected.",
  id_devices_bulk_selected_all_msg: "管 All {{count}} devices are selected.",
  id_devices_bulk_selected_msg: "管 One device is selected.",
  id_devices_bulk_selected_msg_other: "管 {{count}} devices are selected.",
  id_devices_bulk_select_match_current_filter: "管 Select all {{totalCount}} devices that match the current filter.",
  id_devices_bulk_select_all: "管 Select all {{totalCount}} devices.",
  id_devices_delete_count_err_max_limit: "管 A maximum of {{count}} devices can be deleted at a time.",
  id_devices_count_err_internal_error: "管 - Unable to fetch device count, retry",

  //csv import
  add_or_import: "管 Add or Import",
  Import: "管 Import",
  reimport_csv_msg: "管 Reimport the file after fixing the errors.",
  csv_error_msg: "管 There are errors in the uploaded file. Reimport the file after fixing.",
  csv_error_many: "管 There are errors in the uploaded file, displaying the first ten errors:",
  csv_error_guest_admin_not_operator: "管 Guest added by another Sponsor.",
  client_import_success_msg: "管 Client(s) imported successfully.",
  device_import_success_msg: "管 Device(s) imported successfully.",
  device_import_ignore_msg: "管 Device(s) ignored. No changes.",
  device_import_partial_success_msg: "管 Device(s) are partially imported. Rest are ignored.",
  device_import_error_none_imported: "管 No devices imported. There are errors during import.",
  device_import_error_some_imported: " 管 devices imported. Some devices have errors during import.",
  csv_entry_duplicate_error: "管 Line {{index}}: '{{val}}' is repeated as {{displayName}}.",
  Sample: "管 Sample",
  Columns: "管 Columns",
  csv_entry_missing_error: "管 Line {{index}}: {{displayName}} missing.",
  csv_entry_invalid_error: "管 Line {{index}}: '{{val}}' is invalid as {{displayName}}.",
  csv_extra_data_error: "管 Line {{index}}: Extra column.",
  import_max_row_exceeded: "管 A maximum of {{limit}} records can be imported per CSV file.",
  upload_csv_file: "管 Upload CSV file",
  choose_action: "管 Choose Action",

  // Device Administration -> Upsert Device
  err_name_reqd: "你好 - Name is required",
  err_host_reqd: "你好 - Host is required",
  err_port_reqd: "你好 - Port is required",
  err_port_integer: "你好 - The port must be an integer.",
  device_add_success: "你好 - The device was added successfully.",
  device_update_success: "你好 - The device was updated successfully.",
  id_device_add_err_internal_error: "你好 - Add device failed; retry.",
  id_device_update_err_internal_error: "你好 - Update device failed; retry.",
  id_device_get_err_internal_error: "你好 - Delete failed; retry.",
  id_device_add_err_object_already_exists: "你好 - A device with the same name already exists.",
  id_device_err_app_unavailable: "你好 - Connect failed. DeviceAdmin app is not running",

  // Setup
  setup_subheading: "你好 - Setup the DeviceAdmin application to SSH to your devices.",
  device_os: "你好 - Your Client Device OS",
  setup_desc: "你好 - Follow the given steps to install the DeviceAdmin application:",
  setup_step_1: "你好 - 1. Download the DeviceAdmin application for your client.",
  setup_step_2: "你好 - 2. Install the application and allow it to run in the background.",
  setup_step_3: "你好 - 3. When prompted, give permissions for the application to accept incoming connections.",
  setup_step_4: "你好 - 4. In the Self-Service Portal, add the Access Device in the Devices UI.",
  setup_step_5: "你好 - 5. Click the 'Connect' icon for the device to launch the SSH session.",
  ssh_title: "你好 - Device Login Credentials",
  ssh_title_subtitle: "你好 - View device login credentials ",
  ssh_user_name: "你好 - Login Username",
  ssh_helper_text: "你好 - Use device login credentials in external terminals to access devices securely.",
  ssh_password: "你好 - Login Password",
  ssh_password_helper_text: "你好 This is a temporary password that will expire once this web session expires.",
  ssh_menu_item: "你好 - Login Credentials",

  // SSH Passphrase section
  user_onboard_to_network_check_failed: "你好 This user is not allowed to onboard to this network.",
  ssh_token_generate_success: "你好 Device Login Password generated successfully",
  ssh_token_generate_error: "你好 Failed to generate Device Login Password",
  updating_token: "你好 Regenerate device login password",
  ssh_generate_token_message: "你好 Click to generate a device login password.",
  ssh_regenerate_token_message: "你好 Click to regenerate a new device login password.",
  Generate: "你好 Generate",
  ssh_token_one_time_message: "你好 Copy and Save the generated password; it will not be displayed later.",
  update_token_message_part1: "你好 You have a valid device login password that expires at ",
  update_token_message_part2:
    "你好 A new passphrase will overwrite the previous device login password. Are you sure you want to regenerate a new device login password?",
  ssh_token_exist: "你好 Your device login password will expire on ",
  if_required: "你好 Regenerate if required.",
  ssh_token_expired: "你好 Your device login password has expired.",

  // OTP based login
  verify_otp: "你好 Verify one-time password",
  otp_login: "你好 Use one-time password",
  sso_login: "你好 SSO Login",
  enter_otp: "你好 Enter one-time password",
  OTP: "你好 One-time password",
  resend_otp_success: "你好 One-time password has been resent.",
  otp_send_error: "你好 Unable to generate the one-time password; retry.",
  resend_otp: "你好 Resend one-time password",
  submit_btn: "你好 Submit",
  login_invalid_otp: "你好 Invalid one-time password",
  resend_otp_seconds: "你好 Resend one-time password in few seconds",
  otp_sent_info: "你好 Check your email for one-time password.",

  // Guests
  guests: "你好 Guests",
  guests_users: "你好 Users",
  all_users: "你好 All users",
  guest_operator: "你好 Guest Operator",
  guest_sponsor: "你好 Guest Sponsor",
  // Config -> Guests
  id_guests_users: "管 Guest Users",
  id_guests_update: "管 Update Guest User",
  id_guests_desc: "管 Manage the list of Guest Users",
  id_guests_search_desc: "管 Search by Username, Email, Approver, Name or Company ...",
  id_guests_add_import: "管 Add Guest or Import Guests",
  id_guests_add_import_short: "管 Add or Import ",
  // Config -> Guests -> List
  id_guests_onboard_list_err_internal_error: "管 Unable to fetch the list of guests, retry.",
  id_guests_onboard_list_err_invalid_input_orgID: "管 Customer account does not exist.",
  // Config -> Guests -> Delete
  id_guests_delete_msg: "管 Are you sure you want to delete Guest with username '{{entity}}'?",
  id_guests_onboard_delete_err_invalid_input_orgID: "管 Customer account does not exist.",
  id_guests_onboard_delete_err_invalid_input_userID: "管 Selected guest does not exist",
  id_guests_onboard_delete_err_invalid_input_loginName: "管 Selected guest does not exist",
  id_guests_onboard_delete_err_internal_error: "管 Delete failed; retry",
  id_guests_onboard_bulk_delete_msg: "管 Are you sure you want to delete selected Guest?",
  id_guests_onboard_bulk_delete_msg_other: "管 Are you sure you want to delete {{count}} Guests?",
  id_guests_onboard_bulk_delete_all_msg: "管 There is one Guest. Are you sure you want to delete it?",
  id_guests_onboard_bulk_delete_all_msg_other: "管 There are {{count}} Guests. Are you sure you want to delete all of them?",
  id_guests_onboard_bulk_delete_filtered_msg: "管 One Guest matches the current filter applied. Are you sure you want to delete it?",
  id_guests_onboard_bulk_delete_filtered_msg_other:
    "管 {{count}} Guests match the current filter applied. Are you sure you want to delete all of them?",
  id_guests_onboard_bulk_delete_success_msg: "管 One Guest is deleted successfully",
  id_guests_onboard_bulk_delete_success_msg_other: "管 {{count}} Guests are deleted successfully",
  id_guests_onboard_bulk_delete_err: "管 Unable to delete the selected Guest",
  id_guests_onboard_bulk_delete_err_other: "管 Unable to delete {{errCount}} out of {{count}} Guests",
  id_guests_onboard_bulk_delete_err_first_ten: "管 {{count}} Guests are not deleted as they are active. Displaying first 10 below:",
  id_guests_onboard_bulk_delete_internal_err: "管 {{count}} Guests are not deleted due to an internal error.",

  // Config -> Guests -> Upsert
  id_guests_add_or_import_guests: "管 Add or Import Guests",
  id_guests_upsert_desc_add: "管 Provide the following details to add a new guest user or upload a file to import guest users.",
  id_guests_err_username_reqd: "管 Username is required.",
  id_guests_err_email_reqd: "管 Email is required.",
  id_guests_err_email_invalid: "管 Provide a valid email.",
  id_guests_device_limit_err_reqd: "管 Device limit is required",
  id_guests_get_err_internal_error: "管 Unable to fetch guest user, retry.",
  id_guests_portal_list_err_internal_error: "管 Unable to fetch guest portals.",
  id_guests_upsert_action_add: "管 Add",
  id_guests_upsert_action_update: "管 Update",
  id_guests_no_users_import_success_msg: "管 No guest users were imported",
  id_guests_import_success_msg: "管 Guest user(s) imported successfully.",
  id_guests_import_error_none_imported: "管 No guest users imported. There are errors during import.",
  id_guests_import_error_some_imported: " 管 Guest users imported. Some guest users have errors during import.",
  id_guests_user_update_success: "管 Guest user updated successfully",
  id_guests_user_add_success: "管 Guest user added successfully",
  id_guests_upsert_action_add_email: "管 Add and Notify",
  id_guests_upsert_action_update_email: "管 Update and Notify",
  id_guests_import_action_email: "管 Import and Notify",
  id_guests_import_action: "管 Import",

  // bulk
  id_guests_onboard_bulk_selected_filtered_msg: "管 One Guest that match current filter is selected.",
  id_guests_onboard_bulk_selected_filtered_msg_other: "管 {{count}} Guests that match current filter are selected.",
  id_guests_onboard_bulk_selected_all_filtered_msg: "管 All {{count}} Guests that match current filter are selected.",
  id_guests_onboard_bulk_selected_all_msg: "管 All {{count}} Guests are selected.",
  id_guests_onboard_bulk_selected_msg: "管 One Guest is selected.",
  id_guests_onboard_bulk_selected_msg_other: "管 {{count}} Guests are selected.",
  id_guests_onboard_bulk_select_match_current_filter: "管 Select all {{totalCount}} Guests that match the current filter.",
  id_guests_onboard_bulk_select_all: "管 Select all {{totalCount}} Guests.",
  id_guests_onboard_delete_count_err_max_limit: "管 A maximum of {{count}} guest users can be deleted anytime.",

  // Add
  id_guests_valid_from: "管 Valid From",
  id_guests_valid_to: "管 Valid To",
  id_guests_device_limit: "管 Device Limit",
  id_guests_add_err_object_already_exists: "管 A guest user with the same username/email already exists.",
  id_guests_add_err_internal_error: "管 Add failed; retry",
  id_guests_add_err_invalid_input_loginName: "管 Enter a valid username.",
  id_guests_add_err_invalid_input_email: "管 Enter a valid email.",
  id_guests_add_err_invalid_input_orgID: "管 The customer account does not exist.",
  id_guests_add_err_invalid_input_password: "管 Enter a valid password.",
  id_guests_add_err_invalid_input_zoneID: "管 The zone does not exist.",
  // Update
  id_guests_update_err_internal_error: "管 Update failed; retry",
  id_guests_details: "管 Details of selected guest",
  id_guests_update_err_object_already_exists: "管 A guest user with the same username/email already exists.",
  id_guests_update_err_invalid_input_loginName: "管 Enter a valid username.",
  id_guests_update_err_invalid_invalid_input_email: "管 Enter a valid email.",
  id_guests_update_err_invalid_input_orgID: "管 The customer account does not exist.",
  id_guests_update_err_invalid_input_password: "管 Enter a valid password.",
  id_guests_update_err_guest_user_does_not_exist: "管 Guest user does not exist",
  id_guests_user_clients: "管 Guest User Clients",
  id_guests_upsert_desc_update: "管 View guest user details and update the selected guest user",
  id_guests_activation_date: "管 Activation Date",
  id_guests_expiration_date: "管 Expiration Date",
  id_guests_sponsor: "管 Approver",
  id_guests_validity: "管 Validity",
  id_guests_created_at: "管 Created At",
  id_guests_valid_until: "管 Valid until",
  id_guests_devices: "管 Devices",
  id_guests_device: "管 Device",
  id_guests_portal_helptext: "管 Changing the portal updates the validity and device limit.",
  id_guests_invalid_date: "管 Invalid Date",
  id_guests_past_date_error: "管 Date must not be in past",
  id_guests_validTo_lt_validFrom: "管 Valid To should be greater than Valid From",
  id_guests_validity_error_lt_1hr: "管 Validity should be greater than 1 hour",
  id_guests_validity_error_gt_1yr: "管 Validity should be less than 1 year",
  id_guests_validFrom_gt_validTo: "管 Valid From should be greater than Valid To",
  id_guests_validity_error_gt_48hrs: "管 Validity cannot be more than 48 hours",
  translation_days: "管 Days",
  translation_day: "管 Day",
  translation_hours: "管 Hours",
  translation_hour: "管 Hour",
  translation_minutes: "管 Minutes",
  translation_minute: "管 Minute",
  id_guests_additional_guest_information: "管 Additional guest user information",
  id_guests_username: "管 Username",
  id_guests_password: "管 Password",
  id_guests_email: "管 Email",
  id_guests_name: "管 Name",
  id_guests_status: "管 Status",
  id_guests_company: "管 Company",
  id_guests_phone: "管 Phone #",
  id_guests_address: "管 Address",
  id_guests_notes: "管 Notes",
  id_guests_name_max_len_error: "管 The name should have at most 150 characters.",
  id_guests_company_max_len_error: "管 The company name should have at most 150 characters.",
  id_guests_phone_min_len_error: "管 Phone must be 10 characters or more",
  id_guests_phone_max_len_error: "管 Phone should have at most 15 digits.",
  id_guests_phone_country_code_error: "管 Phone must start with + and country code",
  id_guests_address_max_len_error: "管 The address should have at most 300 characters.",
  id_guests_notes_max_len_error: "管 Notes should have at most 150 characters.",
  id_guests_action_add: "管 Add",
  id_guests_action_import: "管 Import",
  id_guests_portal: "管 Portal",
  id_guests_passphrase: "管 Passphrase",
  id_guests_not_applicable: "管 No Limit",
  portal: "管 Portal",
  show_clients: "管 Show Clients",
  hide_clients: "管 Hide Clients",
  id_guests_portal_not_configured: "管 Guest portal with Guestbook is not configured",
  id_guests_action_add_portal_user: "管 Add portal user",
  id_guests_action_add_upsk_user: "管 Add UPSK user",
  export_all: "管 Export All",
  id_guest_user_clients_search_desc: "管 Search by MAC address...",
  id_guests_guestUPSK_network_upsk_guest_not_found: "管 The guest UPSK network is not configured.",
  id_guests_onboard_bulk_export_success_msg: "管 Guest users exported successfully",
  id_guests_onboard_bulk_export_error_internal_error: "管 Unable to export guest users; retry.",
  id_guests_passphrase_qr_code_title: "管 Network QR code for this user",
  id_guests_filter_user_type: "管 Type",
  id_guests_onboard_count_err_internal_error: "管 Failed to fetch guest users count",
  id_guests_users_delete_msg: "管 Are you sure you want to delete Guest User '{{entity}}'?",
  id_guests_upsk_user_msg: "管 This is a UPSK based guest user.",
  id_guests_user_type: "管 Type",
  id_guests_portal_user: "管 Portal",
  id_guests_upsk_user: "管 UPSK",
  id_guest_user_client_list_delete_msg: "管 Are you sure you want to delete the Guest Client with the MAC address: {{entity}}",

  captive_portal_not_allowed: "管 User is not allowed on this network.",
  collapse_sidebar: "管 Collapse Sidebar",
  leave_page: "管 Unsaved Changes",
  unsaved_warning_msg: "管 Your changes may not be saved. Are you sure you want to leave?",
  stay: "管 Stay",
  leave: "管 Leave",
  group_upsk_disabled: "管 Group UPSK is disabled for the selected Client Group.",
  show_details: "管 Show Details",
  passphrase_subtitle_readonly: "管 This is your personal passphrase.",

  client_desc_readonly: "管 Details of selected client",
  device_details: "管 Device Details",
  device_subheading_readonly: "管 Details of selected device",
  ssh_generate_token_readonly_message: "管 Device login password cannot be generated for read-only user.",
  register_new_client_readonly: "管 New client cannot be added for read-only user.",
  client_update_err_user_self_service_portal_write_not_allowed: "管 Client cannot be updated for read-only user.",
  client_delete_err_user_self_service_portal_write_not_allowed: "管 Client cannot be deleted for read-only user.",
  passphrase_generate_err_user_self_service_portal_write_not_allowed: "管 Passphrase cannot be generated for read-only user.",
  client_onboard_err_user_self_service_portal_write_not_allowed: "管 New client cannot be registered for read-only user.",
  user_info_error_internal_error: "管 Unable to fetch user, retry.",
  id_guests_onboard_list_err_guest_admin_not_allowed: "管 You don't have permission to access this page.",
  id_guests_get_err_guest_admin_not_allowed: "管 You don't have permission to access this page.",
  id_guests_add_err_guest_admin_not_allowed: "管 You don't have permission to access this page.",
  id_guests_update_err_guest_admin_not_allowed: "管 You don't have permission to access this page.",
  id_devices_list_err_device_admin_not_allowed: "管 You don't have permission to access this page.",
  id_device_get_err_device_admin_not_allowed: "管 You don't have permission to access this page.",
  id_device_add_err_device_admin_not_allowed: "管 You don't have permission to access this page.",
  id_device_update_err_device_admin_not_allowed: "管 You don't have permission to access this page.",

  invalid_authz_code: "Invalid Authorization Code",
};
