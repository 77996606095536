export const formatImportCSVError = (error: any, t, type) => {
  const errorList = [];
  error?.forEach((e) => {
    e?.entities?.forEach((g) => {
      if (e.error === 'object_already_exists') {
        errorList.push(
          `'${g.entityName}' ${t(`csv_error_${type}_${e.error}`)}`
        );
      } else {
        errorList.push(`'${g.entityName}' ${t(`csv_error_${e.error}`)}`);
      }
    });
  });
  return errorList;
  //"Line 11 : 'Arista_8E:70:EF' name already exist for another device"
};

export const extractNodeNameFromString = (errorString: string) => {
  const match = errorString.match(/https?:\/\/(.*?)(\/|$)/);
  return match[1] || null;
};

export const getCustomErrorMsgCharts = (error: string, t) => {
  const nodeName = extractNodeNameFromString(error);
  const errorCode = error.split(':')[0];
  let errorMsg;
  if (nodeName) {
    errorMsg = t('chart_err_nodeName_' + errorCode, {
      nodeName: nodeName.toString(),
    });
  }
  return errorMsg;
};
