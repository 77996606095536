import { Box, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { showNotification } from '../../utils/notifyutils';
import './Overrides';

// @ts-ignore: Unreachable code error
Quill.register('modules/imageResize', ImageResize);

const CustomBox = styled(Box)<{ hideborder?: string; showtoolbar?: string }>(
  ({ theme, hideborder, showtoolbar }) => ({
    '.ql-toolbar .ql-stroke': {
      stroke: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
    },
    '.ql-picker-options': {
      background:
        theme.palette.mode === 'dark' ? theme.colors.alpha.black[100] : 'white',
    },
    '.ql-toolbar .ql-fill': {
      fill: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
    },
    '.ql-container.ql-snow': {
      border: hideborder ? 'none' : '1px solid black',
      borderColor:
        theme.palette.mode === 'dark' ? theme.colors.borders[2] : '#3A35414D',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderTopRightRadius: showtoolbar ? '0px' : '10px',
      borderTopLeftRadius: showtoolbar ? '0px' : '10px',
    },
    '.ql-toolbar.ql-snow': {
      border: hideborder ? 'none' : '1px solid black',
      borderColor:
        theme.palette.mode === 'dark' ? theme.colors.borders[2] : '#3A35414D',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
    },
    '.ql-snow .ql-tooltip': {
      background:
        theme.palette.mode === 'dark' ? theme.colors.alpha.black[100] : 'white',
      color: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
      input: {
        background:
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.black[100]
            : 'white',
        color: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
      },
    },
    '.ql-tooltip': {
      left: 'unset !important',
    },
    '.ql-toolbar.ql-snow .ql-formats': {
      marginRight: '10px',
    },
  })
);

// Helper Functions
const calculateByteSize = (str: string) => {
  return new Blob([str]).size;
};

const bytesToMB = (bytes: number) => {
  return bytes / (1024 * 1024);
};

const RichTextEditor = ({
  viewer,
  text,
  onUpdate,
  hide,
  placeholder,
  limitInMB,
  required,
  isContentHtml,
  disableImageUpload,
  allowSingleImage,
}: {
  viewer?: boolean;
  text?: any;
  onUpdate?(text: string, error?: boolean, empty?: boolean);
  hide?: boolean;
  placeholder?: string;
  limitInMB?: number;
  required?: boolean;
  isContentHtml?: boolean;
  disableImageUpload?: boolean;
  allowSingleImage?: boolean;
}) => {
  const editorRef = useRef(null);
  const [quill, setQuill] = useState<any>(null);
  const readOnly = useAuth().user.readOnly || viewer;
  const [imageSizeError, setImageSizeError] = useState<boolean>();
  const { t }: { t: any } = useTranslation();
  const [errorStr, setErrorStr] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const textRef = useRef(text);
  const toolbarOptions: any[] = [
    ['bold', 'italic', 'underline'], // toggled buttons
    ['link'], // blocks  // "blockquote", "code-block", "video"

    [{ header: 1 }, { header: 2 }], // custom button values

    // [{ script: "sub" }, { script: "super" }], // superscript/subscript
    // [{ direction: "rtl" }], // text direction

    // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    [{ align: [] }],
  ];

  useEffect(() => {
    if (!isContentHtml) {
      toolbarOptions.push([{ indent: '-1' }, { indent: '+1' }]);
      toolbarOptions.push([{ list: 'ordered' }, { list: 'bullet' }]);
    } else {
      toolbarOptions.push([{ list: 'ordered' }]);
    }
    if (!disableImageUpload) {
      toolbarOptions[1].push('image');
    }
    toolbarOptions.push(['clean']);
  }, [isContentHtml, disableImageUpload]);

  useEffect(() => {
    if (quill) {
      if (viewer && text) {
        if (isContentHtml) {
          quill.root.innerHTML = text;
        } else {
          quill.setContents(JSON.parse(text));
        }
        return;
      }
      if (textRef.current) {
        return;
      }
      textRef.current = text;
      const value = quill.root.innerHTML;
      let isEmpty = false;
      if (!value.includes('data:image/')) {
        const strippedValue = value.replace(/<(.|\n)*?>/g, '').trim();
        // Validate the field and set the error if content is empty
        if (!strippedValue) {
          isEmpty = true;
        }
      } else {
        setErrorStr('');
      }
      if (text && isEmpty) {
        if (isContentHtml) {
          quill.setContents(text);
        } else {
          quill.setContents(JSON.parse(text));
        }
      }
    }
  }, [quill, text, viewer]);

  useEffect(() => {
    if (quill) {
      if (readOnly) {
        quill.disable();
      } else {
        quill.enable();
      }
      return () => {}; // Prevent reinitialization on hot reload
    }
    // @ts-ignore: Unreachable code error
    const quillInstance = new Quill(editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: readOnly
          ? false
          : {
              container: toolbarOptions,
              handlers: {
                image: function () {
                  const existingImages =
                    // @ts-ignore: Unreachable code error
                    quillInstance.container.querySelectorAll('img');
                  if (allowSingleImage && existingImages.length > 0) {
                    showNotification(
                      enqueueSnackbar,
                      t('image_restriction_msg'),
                      'error'
                    );
                    return;
                  }
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');
                  input.click();

                  input.onchange = () => {
                    const file = input.files ? input.files[0] : null;
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        // @ts-ignore: Unreachable code error
                        const range = quillInstance.getSelection();
                        if (e.target) {
                          // @ts-ignore: Unreachable code error
                          quillInstance.insertEmbed(
                            range.index,
                            'image',
                            e.target.result as string
                          );
                        }
                      };
                      reader.readAsDataURL(file);
                    }
                  };
                },
              },
            },

        imageResize: {
          modules: ['Resize', 'DisplaySize'],
        },
      },
      placeholder: readOnly ? '' : placeholder,
    });
    if (text) {
      if (isContentHtml) {
        // @ts-ignore: Unreachable code error
        quillInstance.root.innerHTML = text;
      } else {
        // @ts-ignore: Unreachable code error
        quillInstance.setContents(JSON.parse(text));
      }
    }
    if (viewer) {
      // @ts-ignore: Unreachable code error
      quillInstance.disable();
    } else {
      // @ts-ignore: Unreachable code error
      quillInstance.on('text-change', () => {
        // @ts-ignore: Unreachable code error
        const content = quillInstance.getContents();
        // @ts-ignore: Unreachable code error
        const htmlContent = quillInstance.root.innerHTML;
        if (limitInMB > 0) {
          const contentSizeInBytes = calculateByteSize(htmlContent);
          const mb = bytesToMB(contentSizeInBytes);
          if (mb > limitInMB) {
            onUpdate(JSON.stringify(content), true);
            setImageSizeError(true);
            return;
          }
        }
        let isEmpty = false;
        if (required && !isContentHtml) {
          const strippedValue = htmlContent.replace(/<(.|\n)*?>/g, '').trim();
          // Validate the field and set the error if content is empty
          if (!strippedValue) {
            setErrorStr('err_field_required');
            isEmpty = true;
          } else {
            setErrorStr('');
          }
        } else if (isContentHtml) {
          isEmpty = htmlContent === `<p><br></p>`;
          if (isEmpty && required) {
            setErrorStr('err_field_required');
          }
        }
        if (isContentHtml) {
          // @ts-ignore: Unreachable code error
          if (text !== quillInstance.root.innerHTML) {
            // @ts-ignore: Unreachable code error
            onUpdate(isEmpty ? '' : quillInstance.root.innerHTML, isEmpty);
          }
        } else {
          onUpdate(JSON.stringify(content), isEmpty);
        }
        setImageSizeError(false);
      });
    }
    setQuill(quillInstance);
    return () => {
      quill?.off('text-change');
    };
  }, [
    quill,
    text,
    readOnly,
    onUpdate,
    placeholder,
    limitInMB,
    required,
    viewer,
  ]);

  return (
    <CustomBox
      hideborder={viewer ? 'true' : ''}
      showtoolbar={readOnly ? '' : 'true'}
      mb={1}
    >
      <Box
        ref={editorRef}
        sx={{
          minHeight: '100px',
          ...(hide
            ? { visibility: 'hidden', height: '0px' }
            : {
                visibility: 'visible',
                minHeight: viewer ? '60px' : '120px',
              }),
        }}
      ></Box>
      {imageSizeError && (
        <Typography color="error">
          {t('file_size_exceed_rte', {
            count: limitInMB,
          })}
        </Typography>
      )}
      {errorStr && <Typography color="error">{t(errorStr)}</Typography>}
    </CustomBox>
  );
};

export default RichTextEditor;
