import { AxiosRequestHeaders } from 'axios';
import Papa from 'papaparse';
import { appConfig, redirectURLs } from '../config';
import axios from './axiosutil';

const apiUrls = [
  'me.client.onboard.info',
  'org.admin.user.me',
  'org.license.get',
  'config.deviceAdmin.settings.get',
  'mc.user.me',
  'me.user.info',
  'org.admin.user.me.password.update',
];

export const postAPI = async (
  url: string,
  payload: any,
  callbackFn: any,
  retryCount?: number,
  headers?: AxiosRequestHeaders
) => {
  if (!retryCount && retryCount !== 0) {
    retryCount = 3;
  }
  if (!url || url === '') {
    callbackFn('empty url');
    return;
  }
  try {
    if (headers) {
      headers['Content-Type'] = 'application/json';
    } else {
      headers = {
        'Content-Type': 'application/json',
      };
    }
    const resp = await axios.post('/api/' + url, payload, {
      headers,
    });
    if (
      resp.data['error'] &&
      resp.data['error'] === 'internal_error' &&
      retryCount > 0
    ) {
      if (retryCount === 3 && apiUrls.includes(url)) {
        if (appConfig.appname === 'onboard') {
          const orgID = getOrgIDFromUrl();
          if (orgID) {
            window.location.href = `${appConfig.basename}${orgID}/status/503`;
          }
        } else {
          window.location.href = appConfig.basename + 'status/503';
        }
        return;
      }
      postAPI(url, payload, callbackFn, --retryCount);
    } else if (resp.data['error'] === 'method_not_authorized') {
      window.location.href = appConfig.basename + '403';
    } else if (
      appConfig.appname === 'agni' &&
      resp.data['error'] === 'object_not_found'
    ) {
      let urlFound: boolean;
      for (const iterator of redirectURLs) {
        if (
          window.location.href.includes(iterator.url) &&
          url.includes(iterator.backendAPI)
        ) {
          if (
            !(iterator.skip && window.location.href.includes(iterator.skip))
          ) {
            urlFound = true;
            break;
          }
        }
      }
      if (urlFound) {
        const newURL =
          window.location.protocol +
          '//' +
          window.location.host +
          appConfig.basename +
          '404';
        localStorage.setItem('404Url', window.location.pathname);
        window.location.href = newURL;
      } else {
        callbackFn(resp.data);
      }
    } else if (
      (resp.data['error'] === 'invalid_session_id' ||
        resp.data['error'] === 'invalid_input_sessionID' ||
        resp.data['error'] === 'auth_failed') &&
      !apiUrls.includes(url)
    ) {
      console.log(url, resp.data);
      if (appConfig.appname === 'onboard') {
        window.location.href = payload['orgID'];
      } else {
        window.location.href = appConfig.basename;
      }
    } else {
      if (appConfig.mock === 'true' && !appConfig.unitTest) {
        console.groupCollapsed(url);
        console.log('Payload', payload);
        console.log('Response', resp.data);
        console.groupEnd();
      }
      callbackFn(resp.data);
    }
  } catch (err) {
    console.error(url, err);
    if (err.response) {
      if (err.response.status === 404) {
        window.location.href = appConfig.basename + '404';
      } else if (err.response.status === 502 && retryCount > 0) {
        postAPI(url, payload, callbackFn, --retryCount);
      } else if (err.response.status === 502 && retryCount === 0) {
        if (url !== 'config.concourse.app.verify') {
          window.location.href = appConfig.basename + '502';
        } else {
          callbackFn({ error: 'bad_gateway' });
        }
      }
    }
  }
};

export const postAPIWithResponse = (
  url: string,
  payload: any,
  headers?: AxiosRequestHeaders
) => {
  if (headers) {
    headers['Content-Type'] = 'application/json';
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  }
  return axios.post('/api/' + url, payload, {
    headers,
  });
};

export const postAPIWithResponseAsync = async (
  url: string,
  payload: any,
  headers?: AxiosRequestHeaders
) => {
  if (headers) {
    headers['Content-Type'] = 'application/json';
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  }

  try {
    const resp = await axios.post('/api/' + url, payload, {
      headers,
    });
    if (
      resp.data['error'] === 'invalid_session_id' ||
      resp.data['error'] === 'invalid_input_sessionID' ||
      resp.data['error'] === 'auth_failed'
    ) {
      console.log(url, resp.data);
      window.location.href = appConfig.basename;
    } else {
      return resp.data['error'] ? resp.data : resp.data.data;
    }
  } catch (error) {
    console.error(url, error);
    if (error.response) {
      if (error.response.status === 404) {
        window.location.href = appConfig.basename + '404';
      }
    }
  }
};

export const formAPI = async (url: string, formData: any, callbackFn: any) => {
  try {
    // example: let formData = qs.stringify({ email: "test@abc.com" });
    const resp = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    callbackFn(resp.data);
  } catch (err) {
    console.error(err);
  }
};

export const downloadFile = async (
  url: string,
  payload: any,
  callbackFn: Function,
  orgID?: string,
  retryCount?: number
) => {
  if (!retryCount && retryCount !== 0) {
    retryCount = 3;
  }
  try {
    const response = await axios.post('/api/' + url, payload, {
      responseType: 'blob',
      headers: { 'X-AGNI-ORG-ID': orgID },
    });

    let blob = new Blob([response.data], {
        type: 'application/octet-stream',
      }),
      downloadUrl = window.URL.createObjectURL(blob),
      filename = '',
      disposition = response.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        matches = filenameRegex.exec(disposition);

      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    } else if (retryCount > 0) {
      setTimeout(() => {
        downloadFile(url, payload, callbackFn, orgID, --retryCount);
      }, 20000);
      return;
    } else {
      callbackFn('object_not_found');
      return;
    }
    let a = document.createElement('a');
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl;
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }
    callbackFn('file downloaded');
  } catch (err) {
    callbackFn('internal_error');
  }
};

export const uploadFile = async (
  url: string,
  formData: FormData,
  orgID: string,
  callbackFn: (message: any) => void
) => {
  try {
    const response = await axios({
      method: 'post',
      url: '/api/' + url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-AGNI-ORG-ID': orgID,
      },
    });
    callbackFn(response.data);
  } catch (error) {
    console.log(error);
  }
};

export const getOrgIDFromUrl = (): string => {
  const url = window.location.pathname;
  const parts = url.split('/');
  let orgID = '';
  if (parts.length > 2 && parts[2]) {
    if (appConfig.mock === 'true') {
      orgID = parts[1];
    } else {
      orgID = parts[2];
    }
  }
  return orgID;
};

export const getBaseUrl = (): string => {
  return `${window.location.protocol}//${window.location.host}${appConfig.basename}`;
};

export const createAndDownloadCSV = (
  data,
  filename: string,
  doUnparse?: boolean
) => {
  let csv;
  if (doUnparse) {
    // doUnparse is used generally in sample data where data is of type array of maps
    csv = Papa.unparse(data, { encoding: 'utf-8' });
  } else {
    // used when backend returns a response with csv values
    csv = data;
  }
  var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  var csvURL = null;
  csvURL = window.URL.createObjectURL(csvData);
  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', filename);
  tempLink.click();
  tempLink.remove();
};
