import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import WifiIcon from '@mui/icons-material/Wifi';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import {
  add,
  differenceInHours,
  differenceInMinutes,
  parseISO,
} from 'date-fns';
import { Formik, setNestedObjectValues, yupToFormErrors } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import AAIRadioGroup from '../../components/AAIRadioGroup';
import AAISelect from '../../components/AAISelect';
import AAISwitch from '../../components/AAISwitch';
import AAITextField from '../../components/AAITextField';
import Alert from '../../components/Alert';
import { CopyText } from '../../components/CopyText';
import { FormikDirtyFormPrompt } from '../../components/FormikDirtyFormPrompt';
import ImportCsv from '../../components/ImportCsv';
import PageTitle from '../../components/PageTitle';
import { DeleteActionProps } from '../../components/PageTitle/DeleteAction';
import { PageAction } from '../../components/PageTitle/PageAction';
import PasswordField from '../../components/PasswordField';
import Scrollbar from '../../components/Scrollbar';
import { SsidQrCode } from '../../components/SsidQrCode';
import TimeValidityInput from '../../components/TimeValidityInput';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import { Guest } from '../../models/guest';
import {
  getDiffBetweenDatesString,
  getEpochDateText,
  getEpochUTCDateText,
  getFormattedDateTimeTextShort,
  getFormattedRegularDateTextGivenDateWithAMPM,
  getLocaleDateString,
} from '../../utils/dateutils';
import { formatImportCSVError } from '../../utils/errorutils';
import { postAPI, uploadFile } from '../../utils/httputils';
import { showNotification } from '../../utils/notifyutils';
import {
  getDeviceLimitReadOnlyLabel,
  getGuestStatusLabel,
  GuestDefaults,
  guestUserCsvColumns,
  guestUserSampleData,
} from './GuestConfig';
import GuestClientList from './clients/GuestClientList';

const UpsertGuestUser = ({
  apiPrefix,
  updateURL,
  isOnboard,
  clientListApiURL,
  clientDeleteApiURL,
  clientURLPrefix,
}: {
  apiPrefix: string;
  updateURL: string;
  isOnboard: boolean;
  clientListApiURL?: string;
  clientDeleteApiURL?: string;
  clientURLPrefix?: string;
}) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { titleHeight, tabView } = useContext(GlobalContext);
  const [queryParam] = useSearchParams();

  const action = id === 'new' ? 'add' : 'update';
  const [pgLoaded, setPgLoaded] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState<Guest>(GuestDefaults);
  const [portalList, setPortalList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>();
  const [emailBtnLoading, setEmailBtnLoading] = useState<boolean>();
  const [enableImport, setEnableImport] = useState<boolean>();
  const [file, setFile] = useState<any>();
  const [numOfGuests, setNumOfGuests] = useState<number>();
  const [showClients, setShowClients] = useState(false);
  const [upskValidity, setUpskValidity] = useState(8);
  const [validityString, setValidityString] = useState('-');
  const [isUpskGuest, setIsUpskGuest] = useState(false);
  const [open, setOpen] = useState(false);

  const [importErrorList, setImportErrorList] = useState<string[]>();
  const [importErrorMsg, setImportErrorMsg] = useState<string>();
  const [actionType, setActionType] = useState<string>('add');
  const [errorObject, setErrorObject] = useState({
    validFrom: '',
    validTo: '',
    upskValidTo: '',
  });
  const [guestPSKNetworks, setGuestPSKNetworks] = useState([]);
  const [selectedNetworkID, setSelectedNetworkID] = useState(undefined);
  const [SSID, setSSID] = useState('');

  const handleNetworkChange = (event) => {
    setSelectedNetworkID(event.target.value);
    let selectedNetwork = guestPSKNetworks.filter(
      (e) => e.id === event.target.value
    );
    setSSID(selectedNetwork[0].ssid);
  };

  const getGuestPSKNetworks = useCallback(() => {
    let payload = {
      orgID: auth.user.orgID,
    };
    postAPI('me.guestAdmin.network.guestUPSK.list', payload, (resp) => {
      if (!resp.error || resp.error === '') {
        let applicableNetworks = resp.data.networks;
        setGuestPSKNetworks(applicableNetworks);
        setSelectedNetworkID(applicableNetworks[0].id);
        setSSID(applicableNetworks[0].ssid);
      } else {
        let msg = t('id_guests_guestUPSK_' + resp.error);
        showNotification(enqueueSnackbar, msg, 'error');
      }
    });
  }, [auth.user.orgID, t, enqueueSnackbar]);

  const toggleClients = () => {
    setShowClients(!showClients);
  };

  const validateForm = Yup.object().shape({
    loginName: Yup.string()
      .trim()
      .max(255)
      .when(['actionType'], {
        is: (actionType) => actionType === 'add',
        then: Yup.string().required(t('id_guests_err_username_reqd')),
      }),
    email: Yup.string()
      .email(t('id_guests_err_email_invalid'))
      .max(255)
      .required(t('id_guests_err_email_reqd')),
    deviceLimit: Yup.number()
      .integer()
      .required(t('id_guests_device_limit_err_reqd')),
    name: Yup.string().max(150, t('id_guests_name_max_len_error')),
    company: Yup.string().max(150, t('id_guests_company_max_len_error')),
    phone: Yup.string().test('country-code-validation', function (value) {
      const { path, createError } = this;
      if (!value) {
        return true;
      }
      if (value.length < 10) {
        return createError({
          path,
          message: t('id_guests_phone_min_len_error'),
        });
      }
      if (value.length > 15) {
        return createError({
          path,
          message: t('id_guests_phone_max_len_error'),
        });
      }
      if (!value.startsWith('+')) {
        return createError({
          path,
          message: t('id_guests_phone_country_code_error'),
        });
      }
      return true;
    }),
    address: Yup.string().max(300, t('id_guests_address_max_len_error')),
    notes: Yup.string().max(150, t('id_guests_notes_max_len_error')),
  });

  const updateValidityString = (
    validFrom: Date,
    validTo: Date,
    isUpsk?: boolean
  ) => {
    if (!isUpsk) {
      setValidityString(getDiffBetweenDatesString(validFrom, validTo));
    } else {
      setValidityString(getFormattedRegularDateTextGivenDateWithAMPM(validTo));
    }
  };

  const handleTimeChange = (
    fieldName: string,
    selectedDate: Date | null,
    setFieldValue,
    values
  ) => {
    setFieldValue(fieldName, selectedDate);
    let isValueError = false;
    let error = '';

    try {
      getEpochUTCDateText(selectedDate);
    } catch (err) {
      isValueError = true;
    }

    if (isValueError) {
      setErrorObject((prev) => ({
        ...prev,
        [fieldName]: 'id_guests_invalid_date',
      }));
    } else {
      if (fieldName === 'validTo') {
        if (differenceInMinutes(selectedDate, new Date()) < -5) {
          error = 'id_guests_past_date_error';
        } else {
          let minDiff = differenceInMinutes(selectedDate, values.validFrom);
          if (minDiff <= 0) {
            error = 'id_guests_validTo_lt_validFrom';
          } else if (minDiff > 0 && minDiff <= 59) {
            error = 'id_guests_validity_error_lt_1hr';
          } else if (minDiff > 365 * 24 * 60 - 5) {
            error = 'id_guests_validity_error_gt_1yr';
          } else {
            if (errorObject.validFrom === 'id_guests_validFrom_gt_validTo') {
              setErrorObject((prev) => ({
                ...prev,
                validTo: '',
                validFrom: '',
              }));
            }
            setErrorObject((prev) => ({ ...prev, validTo: '' }));
          }
        }
      } else {
        if (differenceInMinutes(selectedDate, new Date()) < -5) {
          error = 'id_guests_past_date_error';
        } else {
          let minDiff = differenceInMinutes(values.validTo, selectedDate);
          if (minDiff < 60) {
            error = 'id_guests_validFrom_gt_validTo';
          } else {
            if (errorObject.validFrom === 'id_guests_validTo_lt_validFrom') {
              setErrorObject((prev) => ({
                ...prev,
                validTo: '',
                validFrom: '',
              }));
            }
            setErrorObject((prev) => ({ ...prev, validFrom: '' }));
          }
        }
      }
      if (error) {
        setErrorObject((prev) => ({ ...prev, [fieldName]: error }));
      }
    }

    if (!isValueError && !error) {
      if (fieldName === 'validTo') {
        updateValidityString(values.validFrom, selectedDate);
      } else {
        updateValidityString(selectedDate, values.validTo);
      }
    }
  };

  const handleUpskValidityChange = (selectedValidity, setFieldValue) => {
    let validTo = add(new Date(), { hours: selectedValidity });
    if (selectedValidity > 48) {
      setErrorObject((prev) => ({
        ...prev,
        upskValidTo: 'id_guests_validity_error_gt_48hrs',
      }));
    } else {
      setErrorObject((prev) => ({ ...prev, upskValidTo: '' }));
    }
    updateValidityString(new Date(), validTo, true);
    setFieldValue('validTo', validTo);
  };

  const updateSelectedPortalSettings = (portalID, setFieldValue) => {
    let selectedGuestbookPortal = [];
    selectedGuestbookPortal = portalList.filter(
      (portal) => portal.id === portalID
    );
    let guestBookPluginSettings = selectedGuestbookPortal[0].authPlugins.filter(
      (plugin) => plugin.authType === 'guestBook'
    );

    let validFrom = new Date();
    let validTo = add(new Date(), {
      hours: guestBookPluginSettings[0].guestBookDefaultValidity,
    });
    let deviceLimit = guestBookPluginSettings[0].guestBookDeviceLimit;
    setFieldValue('deviceLimit', deviceLimit);
    setFieldValue('validFrom', new Date(validFrom));
    setFieldValue('validTo', new Date(validTo));
    updateValidityString(new Date(validFrom), new Date(validTo));
  };

  const getPortalList = useCallback(async () => {
    const req = {
      orgID: auth.user.orgID,
      skipPreviewImage: true,
    };

    postAPI(
      isOnboard ? 'me.guestAdmin.portal.list' : 'config.portal.list',
      req,
      (resp) => {
        let guestBookPortals = [];
        if (!resp.error || resp.error === '') {
          resp.data.portals.forEach((portal) => {
            let guestBookPluginConfigured = portal.authPlugins?.filter(
              (val) => val.authType === 'guestBook'
            );
            if (guestBookPluginConfigured.length) {
              guestBookPortals.push(portal);
            }
          });
          let validFrom = new Date();
          if (!guestBookPortals.length) {
            setPortalList([]);
            setSelectedGuest({
              ...GuestDefaults,
              validFrom: validFrom,
              validTo: add(new Date(), { hours: 8 }),
            });
          } else {
            setPortalList(guestBookPortals);
            if (id === 'new') {
              let guestBookPluginSettings =
                guestBookPortals[0].authPlugins.filter(
                  (plugin) => plugin.authType === 'guestBook'
                );
              let deviceLimit = guestBookPluginSettings[0].guestBookDeviceLimit;
              let validity =
                guestBookPluginSettings[0].guestBookDefaultValidity;
              let validTo = add(new Date(), { hours: validity });
              setSelectedGuest({
                ...GuestDefaults,
                portalID: guestBookPortals[0].id,
                deviceLimit: deviceLimit,
                validFrom: validFrom,
                validTo: validTo,
              });
              updateValidityString(validFrom, validTo);
            }
          }
        } else {
          showNotification(
            enqueueSnackbar,
            t('id_guests_portal_list_err_' + resp.error),
            'error'
          );
        }
        setPgLoaded(true);
      }
    );
  }, [auth.user.orgID, enqueueSnackbar, t, id]);

  const getGuestUser = useCallback(async () => {
    if (id === 'new') {
      getPortalList();
    } else {
      const req = {
        userID: id,
        orgID: auth.user.orgID,
      };

      postAPI(apiPrefix + '.get', req, (resp) => {
        if (!resp.error || resp.error === '') {
          let portalID = resp.data.portalID;
          if (!portalID) {
            let hoursDiff = differenceInHours(
              parseISO(resp.data.validTo),
              parseISO(resp.data.validFrom)
            );
            setUpskValidity(hoursDiff);
            setIsUpskGuest(true);
            if (isOnboard) {
              getGuestPSKNetworks();
            }
          } else {
            setIsUpskGuest(false);
          }
          updateValidityString(
            new Date(resp.data.validFrom),
            new Date(resp.data.validTo),
            !portalID
          );
          setSelectedGuest((prev) => ({
            ...prev,
            ...resp.data,
            validFrom: new Date(resp.data.validFrom),
            validTo: new Date(resp.data.validTo),
          }));
          if (portalID) {
            getPortalList();
          } else {
            setPgLoaded(true);
          }
        } else {
          showNotification(
            enqueueSnackbar,
            t('id_guests_get_err_' + resp.error),
            'error'
          );
          setPgLoaded(true);
        }
      });
    }
  }, [
    auth.user.orgID,
    enqueueSnackbar,
    id,
    t,
    getPortalList,
    getGuestPSKNetworks,
  ]);

  const handleSubmitFormik = async (
    values,
    {
      setErrors,
      setStatus,
      setSubmitting,
      setFieldTouched,
      resetForm,
      setTouched,
    },
    sendEmail = false
  ) => {
    let errors = {};
    try {
      await validateForm.validate(values, { abortEarly: false });
    } catch (err) {
      errors = yupToFormErrors(err);
      setTouched(setNestedObjectValues(errors, true));
    }

    if (Object.keys(errors).length > 0) {
      return;
    }

    if (
      actionType !== 'add_upsk_user' &&
      (errorObject['validFrom'] || errorObject['validTo'])
    ) {
      return;
    }

    if (
      (actionType === 'add_upsk_user' || isUpskGuest) &&
      errorObject['upskValidTo']
    ) {
      return;
    }

    delete values['dirty'];
    delete values['updatedAt'];
    let payload = {
      ...values,
      sendEmail: sendEmail,
      orgID: auth.user.orgID,
    };
    if (actionType === 'add_upsk_user') {
      payload['loginName'] = payload['email'];
      delete payload['portalID'];
    }

    if (actionType === 'add_upsk_user') {
      payload['validFrom'] = getEpochDateText(new Date());
    } else {
      payload['validFrom'] = getEpochDateText(values['validFrom']);
    }
    payload['validTo'] = getEpochDateText(values['validTo']);

    setSubmitting(true);
    if (sendEmail) {
      setEmailBtnLoading(true);
    } else {
      setLoading(true);
    }
    // make api call here
    postAPI(apiPrefix + '.' + action, payload, (resp) => {
      setSubmitting(false);
      if (sendEmail) {
        setEmailBtnLoading(false);
      } else {
        setLoading(false);
      }
      if (!resp.error || resp.error === '') {
        setErrorObject({ validFrom: '', validTo: '', upskValidTo: '' });
        setStatus({ success: true });
        let msg =
          payload['loginName'] +
          ' : ' +
          (action === 'update'
            ? t('id_guests_user_update_success')
            : t('id_guests_user_add_success'));
        showNotification(enqueueSnackbar, msg, 'success');

        if (action === 'add') {
          navigate(updateURL + '/' + resp.data.userID);
        } else {
          resetForm({ values, dirty: false });
        }
      } else {
        let msg =
          payload['loginName'] +
          ' : ' +
          t('id_guests_' + action + '_err_' + resp.error);
        showNotification(enqueueSnackbar, msg, 'error');
        setFieldTouched('loginName', true, true);
        setStatus({ success: true });
        setErrors({ submit: resp.error });
        if (sendEmail) {
          setEmailBtnLoading(false);
        } else {
          setLoading(false);
        }
      }
    });
  };

  const cancelHandler = () => {
    let from = queryParam.get('from');
    if (from) {
      navigate(from);
    } else {
      navigate(updateURL);
    }
  };

  const fileHandler = (errFound: boolean, file: any, count: number) => {
    setEnableImport(!errFound);
    if (!errFound) {
      setFile(file);
      setNumOfGuests(count);
    }
  };

  const importHandler = (values, resetForm, sendEmail) => {
    setImportErrorMsg(undefined);
    setImportErrorList([]);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('portalID', values['portalID']);
    formData.append('validFrom', getEpochDateText(values['validFrom']));
    formData.append('validTo', getEpochDateText(values['validTo']));
    formData.append('deviceLimit', values['deviceLimit']);
    formData.append('sendEmail', sendEmail.toString());

    if (errorObject['validFrom'] || errorObject['validTo']) {
      return;
    }

    if (sendEmail) {
      setEmailBtnLoading(true);
    } else {
      setLoading(true);
    }

    uploadFile(
      apiPrefix + 's.import',
      formData,
      auth.user.orgID,
      (resp: any) => {
        if (!resp.error || resp.error === '') {
          const errMsg =
            numOfGuests === 0
              ? 'id_guests_no_users_import_success_msg'
              : 'id_guests_import_success_msg';
          resetForm({ values, dirty: false });
          showNotification(enqueueSnackbar, t(errMsg), 'success');
          if (sendEmail) {
            setEmailBtnLoading(false);
          } else {
            setLoading(false);
          }
          cancelHandler();
        } else {
          if (resp.data?.errCount) {
            if (resp.data?.totalCount === resp.data?.errCount) {
              setImportErrorMsg(t('id_guests_import_error_none_imported'));
            } else {
              const diff = resp.data.totalCount - resp.data.errCount;
              setImportErrorMsg(
                diff + t('id_guests_import_error_some_imported')
              );
            }
            setImportErrorList(
              formatImportCSVError(resp.data.errors, t, 'guest')
            );
          }
          if (sendEmail) {
            setEmailBtnLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    );
  };

  const deleteAction: DeleteActionProps = {
    id: selectedGuest.userID,
    deleteMsg: 'id_guests_users_delete_msg',
    entityName: selectedGuest.email,
    caption: 'Delete',
    title: 'Guest User',
    icon: true,
    resourceName: 'users',
    listResourceName: isOnboard ? 'id_guests_onboard' : 'id_guests',
    deleteUrl: isOnboard
      ? 'me.guestAdmin.user.delete'
      : 'identity.guest.user.delete',
    callbackHandler: cancelHandler,
    deleteErrorHandler: () => {},
  };

  const pageActions: PageAction[] = [
    {
      caption: 'Back',
      icon: ArrowBackIcon,
      callbackHandler: cancelHandler,
      showForReadonlyUser: true,
    },
  ];

  const getPortalName = (portalID) => {
    let filteredPortalList = portalList.filter((f) => f.id === portalID);
    if (filteredPortalList.length > 0) {
      return filteredPortalList[0].name;
    }
    return '';
  };

  useEffect(() => {
    getGuestUser();
  }, [getGuestUser]);

  useEffect(() => {
    if (actionType === 'add_upsk_user' && SSID === '') {
      getGuestPSKNetworks();
    }
  }, [actionType, getGuestPSKNetworks]);

  return (
    <>
      <Helmet>
        <title>
          {id === 'new'
            ? t('id_guests_add_or_import_guests')
            : auth.user.readOnly
            ? selectedGuest.email
            : t('id_guests_update')}
        </title>
      </Helmet>
      <Grid
        item
        xs={12}
        sm={isOnboard && isUpskGuest ? 12 : 10}
        md={isOnboard && isUpskGuest ? 12 : 8}
      >
        <PageTitle
          heading={
            id === 'new'
              ? t('id_guests_add_or_import_guests')
              : auth.user.readOnly
              ? selectedGuest.email
              : t('id_guests_update')
          }
          icon={FollowTheSignsIcon}
          subHeading={
            auth.user.readOnly
              ? t('id_guests_details')
              : t('id_guests_upsert_desc_' + action)
          }
          actions={pageActions}
          deleteAction={id === 'new' ? null : deleteAction}
        ></PageTitle>
      </Grid>
      <Box sx={{ height: `calc(100vh - ${titleHeight}px)` }}>
        {pgLoaded && (
          <Scrollbar>
            <Formik
              initialValues={selectedGuest}
              validationSchema={validateForm}
              onSubmit={handleSubmitFormik}
              enableReinitialize
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                dirty,
                resetForm,
                setErrors,
                setStatus,
                setSubmitting,
                setFieldTouched,
                setTouched,
              }): JSX.Element => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormikDirtyFormPrompt />
                    <Box mb={1} display="flex">
                      <Grid
                        container
                        direction="row"
                        alignItems="stretch"
                        spacing={1}
                      >
                        <Grid item xs={12} sm={10} md={8}>
                          <Card
                            sx={{
                              m: 2,
                              mt: 0,
                              pt: 1,
                            }}
                          >
                            {action === 'add' && (
                              <AAIRadioGroup
                                label={t('choose_action')}
                                id="id_choose_action"
                                row
                                aria-labelledby="actionType"
                                name="actionType"
                                value={actionType}
                                onChange={(e) => {
                                  setFieldValue('dirty', true);
                                  setActionType(e.target.value);
                                }}
                                sx={{ display: 'flex', gap: '20px' }}
                              >
                                <FormControlLabel
                                  id="id_choose_action_option_add_portal_user"
                                  key={'add'}
                                  value={'add'}
                                  control={<Radio />}
                                  label={
                                    !isOnboard ||
                                    (isOnboard &&
                                      !auth.user.isGuestAdminOperator)
                                      ? t('id_guests_action_add')
                                      : t('id_guests_action_add_portal_user')
                                  }
                                />
                                {isOnboard &&
                                  auth.user.isGuestAdminOperator && (
                                    <FormControlLabel
                                      id="id_choose_action_option_add_upsk_user"
                                      key={'add_upsk_user'}
                                      value={'add_upsk_user'}
                                      control={<Radio />}
                                      label={t(
                                        'id_guests_action_add_upsk_user'
                                      )}
                                    />
                                  )}
                                <FormControlLabel
                                  id="id_choose_action_option_import"
                                  key={'import'}
                                  value={'import'}
                                  control={<Radio />}
                                  label={t('id_guests_action_import')}
                                />
                              </AAIRadioGroup>
                            )}

                            {actionType !== 'import' && (
                              <>
                                {!isUpskGuest &&
                                  actionType !== 'add_upsk_user' && (
                                    <AAITextField
                                      id="id_loginName_text"
                                      error={Boolean(
                                        touched.loginName && errors.loginName
                                      )}
                                      fullWidth
                                      helperText={
                                        touched.loginName && errors.loginName
                                      }
                                      label={t('id_guests_username')}
                                      name="loginName"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.loginName}
                                      variant="outlined"
                                      size="medium"
                                    />
                                  )}

                                {!isUpskGuest &&
                                  !auth.user.readOnly &&
                                  values.password && (
                                    <Box p={2} pb={1}>
                                      <Grid container>
                                        <Grid item xs={12} md={10} lg={8}>
                                          <PasswordField
                                            label={t('id_guests_password')}
                                            value={values.password}
                                          ></PasswordField>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          md={2}
                                          lg={3}
                                          alignItems="center"
                                          sx={{
                                            display: 'flex',
                                            mt: '0.5em',
                                            pl: 1,
                                          }}
                                        >
                                          <CopyText
                                            text={values.password}
                                            copyTxt={t('Copy')}
                                            copiedTxt={t('Copied')}
                                            height="1em"
                                            useBtn
                                          />
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}

                                <AAITextField
                                  id="id_email_text"
                                  error={Boolean(touched.email && errors.email)}
                                  fullWidth
                                  helperText={touched.email && errors.email}
                                  label={t('id_guests_email')}
                                  name="email"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email}
                                  variant="outlined"
                                />
                              </>
                            )}

                            {action === 'update' &&
                              values.sponsorName &&
                              (isOnboard
                                ? auth.user.isGuestAdminOperator
                                : true) && (
                                <AAITextField
                                  id="id_sponsorName_text"
                                  fullWidth
                                  label={t('id_guests_sponsor')}
                                  name="sponsorName"
                                  value={values.sponsorName}
                                  variant="outlined"
                                  disabled
                                />
                              )}

                            {actionType !== 'add_upsk_user' && !isUpskGuest && (
                              <>
                                {auth.user.readOnly ? (
                                  <AAITextField
                                    id="id_guests_portal"
                                    fullWidth
                                    label={t('id_guests_portal')}
                                    name="portalName"
                                    value={getPortalName(values.portalID)}
                                    variant="outlined"
                                  ></AAITextField>
                                ) : (
                                  <FormControl fullWidth variant="outlined">
                                    <AAISelect
                                      id="id_portal_select"
                                      labelId="portal-label"
                                      name="portalID"
                                      value={values.portalID}
                                      label={t('id_guests_portal')}
                                      onChange={(e) => {
                                        handleChange(e);
                                        updateSelectedPortalSettings(
                                          e.target.value,
                                          setFieldValue
                                        );
                                      }}
                                      size="medium"
                                      caption="portal"
                                      helper_component={
                                        portalList.length === 0 ? (
                                          <>
                                            {t(
                                              'id_guests_portal_not_configured'
                                            )}{' '}
                                            {!isOnboard && (
                                              <>
                                                <Typography
                                                  id="id_guests_create_portal_link"
                                                  component={Link}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                    navigate(
                                                      '/identity/guests/portals'
                                                    )
                                                  }
                                                >
                                                  {t('click_here')}
                                                </Typography>
                                                <Box
                                                  component="span"
                                                  sx={{
                                                    pl: 0.5,
                                                  }}
                                                >
                                                  {t('id_guests_create_portal')}
                                                </Box>
                                              </>
                                            )}
                                          </>
                                        ) : action === 'update' ? (
                                          <>{t('id_guests_portal_helptext')}</>
                                        ) : (
                                          ''
                                        )
                                      }
                                      error={
                                        pgLoaded && portalList.length === 0
                                      }
                                    >
                                      {portalList.map((portal) => (
                                        <MenuItem
                                          id={`id_portal_select_option_${portal.name}`}
                                          key={portal.id}
                                          value={portal.id}
                                        >
                                          {t(portal.name)}
                                        </MenuItem>
                                      ))}
                                    </AAISelect>
                                  </FormControl>
                                )}
                              </>
                            )}

                            {action === 'update' &&
                              !auth.user.readOnly &&
                              isUpskGuest && (
                                <>
                                  <Grid>
                                    <Typography
                                      sx={{ px: 2, py: 1 }}
                                      variant="subtitle2"
                                    >
                                      <Alert severity="info">
                                        {t('id_guests_upsk_user_msg')}
                                      </Alert>
                                    </Typography>
                                  </Grid>
                                  <Grid container p={2} py={1}>
                                    <Grid item xs={12} md={10} lg={8}>
                                      <PasswordField
                                        label={t('id_guests_passphrase')}
                                        value={values.pskPassphrase}
                                      ></PasswordField>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      md={2}
                                      lg={3}
                                      alignItems="center"
                                      sx={{
                                        display: 'flex',
                                        mt: '0.5em',
                                        pl: 1,
                                      }}
                                    >
                                      <CopyText
                                        text={values.pskPassphrase}
                                        copyTxt={t('Copy')}
                                        copiedTxt={t('Copied')}
                                        height="1em"
                                        useBtn
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              )}

                            {auth.user.readOnly && (
                              <>
                                <AAITextField
                                  id="id_guests_from_text"
                                  fullWidth
                                  label={t(
                                    isUpskGuest
                                      ? t('id_guests_created_at')
                                      : t('id_guests_valid_from')
                                  )}
                                  name="validFrom"
                                  value={getFormattedDateTimeTextShort(
                                    values.validFrom
                                  )}
                                  variant="outlined"
                                ></AAITextField>

                                {!isUpskGuest &&
                                actionType !== 'add_upsk_user' ? (
                                  <AAITextField
                                    id="id_guests_to_text"
                                    fullWidth
                                    label={t(t('id_guests_valid_to'))}
                                    name="validTo"
                                    value={getFormattedDateTimeTextShort(
                                      values.validTo
                                    )}
                                    variant="outlined"
                                  ></AAITextField>
                                ) : (
                                  <AAITextField
                                    id="id_guests_validity"
                                    fullWidth
                                    label={t('id_guests_validity')}
                                    name="validity"
                                    value={
                                      upskValidity +
                                      ' ' +
                                      t('translation_hours')
                                    }
                                    variant="outlined"
                                  ></AAITextField>
                                )}

                                <AAITextField
                                  id="id_guests_validity_text"
                                  fullWidth
                                  label={
                                    isUpskGuest
                                      ? t('id_guests_valid_until')
                                      : t('id_guests_validity')
                                  }
                                  name="validity"
                                  value={validityString}
                                  variant="outlined"
                                ></AAITextField>
                              </>
                            )}

                            {!auth.user.readOnly && (
                              <Grid container px={1} pt={1} pb={0}>
                                {(actionType !== 'add_upsk_user' ||
                                  action === 'update') && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    display="flex"
                                    alignItems="center"
                                    px={1}
                                  >
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <DateTimePicker
                                          label={
                                            isUpskGuest
                                              ? t('id_guests_created_at')
                                              : t('id_guests_valid_from')
                                          }
                                          value={values.validFrom}
                                          onChange={(newVal) =>
                                            handleTimeChange(
                                              'validFrom',
                                              newVal,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          inputFormat={
                                            getLocaleDateString() + ' hh:mm a'
                                          }
                                          // disablePast
                                          disabled={isUpskGuest}
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <FormHelperText
                                          error={Boolean(errorObject.validFrom)}
                                        >
                                          {t(errorObject.validFrom)} &nbsp;
                                        </FormHelperText>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}

                                {!isUpskGuest &&
                                actionType !== 'add_upsk_user' ? (
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    display="flex"
                                    alignItems="center"
                                    px={1}
                                  >
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <DateTimePicker
                                          label={t('id_guests_valid_to')}
                                          value={values.validTo}
                                          onChange={(newVal) =>
                                            handleTimeChange(
                                              'validTo',
                                              newVal,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          inputFormat={
                                            getLocaleDateString() + ' hh:mm a'
                                          }
                                          // disablePast
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <FormHelperText
                                          error={Boolean(errorObject.validTo)}
                                        >
                                          {t(errorObject.validTo)} &nbsp;
                                        </FormHelperText>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    display="flex"
                                    alignItems="center"
                                    px={1}
                                  >
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <TimeValidityInput
                                          label={t('id_guests_validity')}
                                          initValue={upskValidity}
                                          onChange={function (
                                            newValue: number
                                          ) {
                                            setUpskValidity(newValue);
                                            handleUpskValidityChange(
                                              newValue,
                                              setFieldValue
                                            );
                                          }}
                                          error={false}
                                          helperText={t('')}
                                          onlyShowHrs
                                          width={tabView ? '200px' : '226px'}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormHelperText
                                          error={Boolean(
                                            errorObject.upskValidTo
                                          )}
                                        >
                                          {t(errorObject.upskValidTo)} &nbsp;
                                        </FormHelperText>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}

                                {(actionType !== 'add_upsk_user' ||
                                  action === 'update') && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    display="flex"
                                    alignItems="center"
                                    px={1}
                                  >
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography id="id_validity_text">
                                          {isUpskGuest
                                            ? t('id_guests_valid_until')
                                            : t('id_guests_validity')}
                                          : <Chip label={validityString}></Chip>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormHelperText>&nbsp;</FormHelperText>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            )}

                            <AAISelect
                              id="id_deviceLimit_text"
                              labelId="deviceLimit-label"
                              name="deviceLimit"
                              value={values.deviceLimit}
                              label={t('id_guests_device_limit')}
                              onChange={handleChange}
                              size="medium"
                              caption={t('id_guests_device_limit')}
                              value_decorator={getDeviceLimitReadOnlyLabel}
                            >
                              <MenuItem
                                id={`id_deviceLimit_select_option_0`}
                                key={0}
                                value={0}
                              >
                                {t('id_guests_not_applicable')}
                              </MenuItem>
                              {Array.from({ length: 10 }, (_, i) => i + 1).map(
                                (limit) => (
                                  <MenuItem
                                    id={`id_deviceLimit_select_option_${limit}`}
                                    key={limit}
                                    value={limit}
                                  >
                                    {limit}
                                  </MenuItem>
                                )
                              )}
                            </AAISelect>

                            {actionType === 'import' && (
                              <Grid container p={2}>
                                <Grid item xs={12}>
                                  <ImportCsv
                                    columns={guestUserCsvColumns}
                                    fileImportedCallback={fileHandler}
                                    // reset={resetCsv}
                                    sampleData={guestUserSampleData}
                                    resourceName="guest-users"
                                    fileBrowseCallback={() => {
                                      setImportErrorMsg(undefined);
                                      setImportErrorList([]);
                                    }}
                                    importErrorList={importErrorList}
                                    importErrorMsg={importErrorMsg}
                                    maxLimit={1000}
                                  />
                                </Grid>
                              </Grid>
                            )}

                            {action === 'update' && (
                              <AAISwitch
                                id="id_status_switch"
                                color="primary"
                                name="status"
                                value={values.status}
                                checked={values.status === 'enabled'}
                                onChange={(_event, checked) => {
                                  setFieldValue(
                                    'status',
                                    checked ? 'enabled' : 'disabled'
                                  );
                                }}
                                status_caption={t('id_guests_status')}
                                value_decorator={getGuestStatusLabel}
                                variant="caption-value"
                              />
                            )}
                          </Card>

                          {actionType !== 'import' && (
                            <Card
                              sx={{
                                m: 2,
                              }}
                            >
                              <CardHeader
                                title={t(
                                  'id_guests_additional_guest_information'
                                )}
                                action={
                                  <IconButton
                                    aria-label="collapse"
                                    onClick={() => {
                                      setOpen(!open);
                                    }}
                                  >
                                    {!open ? (
                                      <ExpandMoreIcon />
                                    ) : (
                                      <ExpandLessIcon />
                                    )}
                                  </IconButton>
                                }
                              />

                              {open && (
                                <>
                                  <Divider sx={{ mb: 1 }} />
                                  <AAITextField
                                    id="id_name_text"
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label={t('id_guests_name')}
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    variant="outlined"
                                    size="medium"
                                  />
                                  <AAITextField
                                    id="id_company_text"
                                    error={Boolean(
                                      touched.company && errors.company
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.company && errors.company
                                    }
                                    label={t('id_guests_company')}
                                    name="company"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.company}
                                    variant="outlined"
                                    size="medium"
                                  />
                                  <AAITextField
                                    id="id_phone_text"
                                    error={Boolean(
                                      touched.phone && errors.phone
                                    )}
                                    fullWidth
                                    helperText={touched.phone && errors.phone}
                                    label={t('id_guests_phone')}
                                    name="phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                    variant="outlined"
                                    size="medium"
                                  />
                                  <AAITextField
                                    id="id_address_text"
                                    error={Boolean(
                                      touched.address && errors.address
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.address && errors.address
                                    }
                                    label={t('id_guests_address')}
                                    name="address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address}
                                    variant="outlined"
                                    size="medium"
                                  />
                                  <AAITextField
                                    id="id_notes_text"
                                    error={Boolean(
                                      touched.notes && errors.notes
                                    )}
                                    fullWidth
                                    helperText={touched.notes && errors.notes}
                                    label={t('id_guests_notes')}
                                    name="notes"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.notes}
                                    variant="outlined"
                                    size="medium"
                                  />
                                </>
                              )}
                            </Card>
                          )}

                          {!auth.user.readOnly && (
                            <DialogActions
                              sx={{
                                px: 3,
                              }}
                            >
                              <Button
                                id="id_button_cancel"
                                color="secondary"
                                onClick={cancelHandler}
                              >
                                {t('Cancel')}
                              </Button>
                              {actionType !== 'import' ? (
                                <>
                                  <Button
                                    id="id_button_add"
                                    type="submit"
                                    startIcon={
                                      isSubmitting && loading ? (
                                        <CircularProgress size="1rem" />
                                      ) : null
                                    }
                                    disabled={
                                      Boolean(errors.submit) ||
                                      isSubmitting ||
                                      !dirty ||
                                      emailBtnLoading ||
                                      (action === 'update' &&
                                        !isUpskGuest &&
                                        !portalList.length) ||
                                      (action === 'add' &&
                                        actionType !== 'add_upsk_user' &&
                                        !portalList.length) ||
                                      (actionType === 'add_upsk_user' &&
                                        !guestPSKNetworks.length) ||
                                      (isUpskGuest &&
                                        isOnboard &&
                                        !guestPSKNetworks.length)
                                    }
                                    variant="contained"
                                  >
                                    {t('id_guests_upsert_action_' + action)}
                                  </Button>
                                  <Button
                                    id="id_button_add_email"
                                    type="submit"
                                    startIcon={
                                      isSubmitting && emailBtnLoading ? (
                                        <CircularProgress size="1rem" />
                                      ) : null
                                    }
                                    disabled={
                                      Boolean(errors.submit) ||
                                      isSubmitting ||
                                      !dirty ||
                                      loading ||
                                      (action === 'update' &&
                                        !isUpskGuest &&
                                        !portalList.length) ||
                                      (action === 'add' &&
                                        actionType !== 'add_upsk_user' &&
                                        !portalList.length) ||
                                      (actionType === 'add_upsk_user' &&
                                        !guestPSKNetworks.length) ||
                                      (isUpskGuest &&
                                        isOnboard &&
                                        !guestPSKNetworks.length)
                                    }
                                    variant="contained"
                                    onClick={() => {
                                      handleSubmitFormik(
                                        values,
                                        {
                                          setErrors,
                                          setStatus,
                                          setSubmitting,
                                          setFieldTouched,
                                          resetForm,
                                          setTouched,
                                        },
                                        true
                                      );
                                    }}
                                  >
                                    {t(
                                      'id_guests_upsert_action_' +
                                        action +
                                        '_email'
                                    )}
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <LoadingButton
                                    id="id_button_import"
                                    loading={loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      importHandler(values, resetForm, false)
                                    }
                                    disabled={
                                      !enableImport ||
                                      emailBtnLoading ||
                                      !portalList.length
                                    }
                                  >
                                    {t('id_guests_import_action')}
                                  </LoadingButton>
                                  <LoadingButton
                                    id="id_button_import_email"
                                    loading={emailBtnLoading}
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      importHandler(values, resetForm, true)
                                    }
                                    disabled={
                                      !enableImport ||
                                      loading ||
                                      !portalList.length
                                    }
                                  >
                                    {t('id_guests_import_action_email')}
                                  </LoadingButton>
                                </>
                              )}
                            </DialogActions>
                          )}
                        </Grid>
                        {isOnboard &&
                          isUpskGuest &&
                          guestPSKNetworks.length > 0 && (
                            <Grid item xs={12} sm={10} md={4}>
                              <Grid
                                container
                                direction="row"
                                alignItems="stretch"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <Card
                                    sx={{
                                      m: 2,
                                      mt: 0,
                                      pt: 1,
                                      pb: 0.5,
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      p={2}
                                    >
                                      <Typography variant="h5" lineHeight={1}>
                                        {t(
                                          'id_guests_passphrase_qr_code_title'
                                        )}
                                      </Typography>
                                    </Box>
                                    <Divider />
                                    {guestPSKNetworks.length === 1 && (
                                      <>
                                        <Box
                                          sx={{
                                            mt: 2,
                                            mb: 1,
                                          }}
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                              pl: 2,
                                              pb: 1,
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Grid item>
                                              <Typography variant="subtitle2">
                                                {t('wireless_network')}:
                                              </Typography>
                                            </Grid>
                                            <Grid item>
                                              <Chip
                                                icon={<WifiIcon />}
                                                color="primary"
                                                variant="outlined"
                                                label={SSID}
                                                sx={{ mr: 1 }}
                                              ></Chip>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          sx={{
                                            width: 1,
                                            my: 2,
                                            textAlign: 'center',
                                          }}
                                        >
                                          <SsidQrCode
                                            authentication={'WPA'}
                                            ssidName={SSID}
                                            password={values.pskPassphrase}
                                          ></SsidQrCode>
                                        </Box>
                                      </>
                                    )}
                                    {guestPSKNetworks.length > 1 &&
                                      selectedNetworkID && (
                                        <>
                                          <Box
                                            sx={{
                                              my: 2,
                                            }}
                                          >
                                            <Grid item xs={12}>
                                              <AAISelect
                                                id="id_network_select"
                                                labelId="network-label"
                                                value={selectedNetworkID}
                                                onChange={handleNetworkChange}
                                                label={t('network_ssid')}
                                                sx={{ top: 0 }}
                                                name="network"
                                                caption={t('network_ssid')}
                                              >
                                                {guestPSKNetworks.map(
                                                  (network) => (
                                                    <MenuItem
                                                      id={`id_network_select_option_${network.ssid}`}
                                                      key={network.ssid}
                                                      value={network.id}
                                                    >
                                                      {network.ssid}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </AAISelect>
                                            </Grid>
                                          </Box>
                                          <Box
                                            sx={{
                                              width: 1,
                                              my: 2,
                                              textAlign: 'center',
                                            }}
                                          >
                                            <SsidQrCode
                                              authentication={'WPA'}
                                              ssidName={SSID}
                                              password={values.pskPassphrase}
                                            ></SsidQrCode>
                                          </Box>
                                        </>
                                      )}
                                  </Card>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Box>
                    {action === 'update' && (
                      <Grid
                        container
                        direction="row"
                        alignItems="stretch"
                        spacing={1}
                      >
                        <Grid item xs={12} sm={10} md={8}>
                          <GuestClientList
                            userID={id}
                            forceUpdate={false}
                            cancelHandler={cancelHandler}
                            showClients={showClients}
                            toggleClients={toggleClients}
                            isOnboard={isOnboard}
                            listApiURL={clientListApiURL}
                            deleteApiURL={clientDeleteApiURL}
                            URLPrefix={clientURLPrefix}
                          ></GuestClientList>
                        </Grid>
                      </Grid>
                    )}
                  </form>
                );
              }}
            </Formik>
          </Scrollbar>
        )}
      </Box>
    </>
  );
};

export default UpsertGuestUser;
