import CorporateFare from '@mui/icons-material/CorporateFare';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import LockResetIcon from '@mui/icons-material/LockReset';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Popover,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { appConfig } from '../../config';
import useAuth from '../../hooks/useAuth';
import { getFirstLetter } from '../../utils';
import { showNotification } from '../../utils/notifyutils';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding: ${theme.spacing(0, 1)};
        height: ${theme.spacing(7)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.text.primary};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.text.primary}
`
);

function Userbox(props) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const contrastHeader = theme.colors.primary.main !== theme.header.background;
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await props.logoutCallback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCopyOrgId = () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(props.orgId).then(
      () => {
        showNotification(enqueueSnackbar, 'Copied', 'success');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const handleResetPassword = () => {
    if (props.handleResetPassword) {
      props.handleResetPassword();
    } else {
      navigate('/resetpassword');
    }
  };

  return (
    <>
      <UserBoxButton
        id="id_button_user_toggle"
        data-testid="id_button_user_toggle"
        color="primary"
        ref={ref}
        onClick={handleOpen}
      >
        {props.mode === 'dark' ? (
          <Avatar
            variant="circular"
            sx={{
              backgroundColor: contrastHeader
                ? theme.colors.primary.main
                : theme.colors.alpha.white[100],
              color: contrastHeader
                ? theme.colors.alpha.white[100]
                : theme.colors.primary.main,
            }}
          >
            {getFirstLetter(props.userName)}
          </Avatar>
        ) : (
          <Avatar
            variant="circular"
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            {getFirstLetter(props.userName)}
          </Avatar>
        )}
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 240,
            display: { md: 'none', xs: 'inline-flex' },
            alignItems: 'center',
          }}
          display="flex"
        >
          <Avatar
            variant="circular"
            sx={{ backgroundColor: theme.colors.primary.main }}
          >
            {getFirstLetter(props.userName)}
          </Avatar>
          <UserBoxText>
            <UserBoxLabel variant="body1">{props.userName}</UserBoxLabel>
            {props.userRole && (
              <UserBoxDescription variant="body2">
                {t(props.userRole)}
              </UserBoxDescription>
            )}
            <UserBoxDescription variant="body2">
              {t(props.email)}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'flex ' },
          }}
          flexDirection="column"
          alignItems={'center'}
          padding={'16px'}
        >
          <Avatar
            variant="circular"
            sx={{
              backgroundColor: theme.colors.primary.main,
              color: theme.colors.alpha.white[100],
              width: 60,
              height: 60,
              fontSize: 20,
            }}
          >
            {getFirstLetter(props.userName)}
          </Avatar>
          <UserBoxText>
            <UserBoxLabel variant="body1" textAlign="center" marginTop={'8px'}>
              {props.userName}
            </UserBoxLabel>
            {props.userRole && (
              <UserBoxDescription variant="body2" textAlign="center">
                {t(props.userRole)}
              </UserBoxDescription>
            )}
          </UserBoxText>
          <UserBoxDescription variant="body2" textAlign="center">
            {props.email}
          </UserBoxDescription>
        </Box>
        {props.orgId && (
          <>
            <Divider
              sx={{
                mb: 0,
              }}
            />
            <Box
              component="span"
              sx={{
                display: { md: 'flex ' },
              }}
              flexDirection="column"
              alignItems={'center'}
              justifyContent={'center'}
              flexWrap="wrap"
              padding={'8px'}
              m={1}
            >
              <Button
                id="id_button_copy_ord_id"
                color="primary"
                fullWidth
                onClick={handleCopyOrgId}
              >
                <CorporateFare
                  sx={{
                    mr: 1,
                  }}
                />
                {t('Copy Organization ID')}
              </Button>
            </Box>
          </>
        )}

        {(props.resetPassword ||
          (auth.user.userType === 'local' &&
            appConfig.appname === 'launchpad')) && (
          <>
            <Divider
              sx={{
                mb: 0,
              }}
            />
            <Box
              component="span"
              sx={{
                display: { md: 'flex ' },
              }}
              flexDirection="column"
              alignItems={'center'}
              justifyContent={'center'}
              flexWrap="wrap"
              padding={'8px'}
              m={1}
            >
              <Button
                id="id_button_reset_password"
                data-testid="id_button_reset_password"
                color="primary"
                fullWidth
                onClick={handleResetPassword}
              >
                <LockResetIcon
                  sx={{
                    mr: 1,
                  }}
                />
                {t('Change Password')}
              </Button>
            </Box>
          </>
        )}
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <Box
          sx={{
            minWidth: 180,
          }}
          m={1}
        >
          <Button
            id="id_button_logout"
            data-testid="id_button_logout"
            color="primary"
            fullWidth
            onClick={handleLogout}
          >
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t('Sign Out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Userbox;
