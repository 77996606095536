import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import Papa from 'papaparse';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAndDownloadCSV } from '../../utils/httputils';
import { getVendorError, isValidMACAddress } from '../../utils/macutil';
import {
  isEmailValid,
  isIPV4Valid,
  isIPV6Valid,
  isNumeric,
  validatePhoneNumber,
} from '../../utils/validatorutils';

// Allowed extensions for input file
const allowedExtensions = ['csv'];

export type CsvColumn = {
  displayName: string;
  type: 'mac' | 'string' | 'ip' | 'vendor' | 'number' | 'email' | 'phone';
  checkEmptyValue?: boolean;
  uniqueValue?: boolean;
};

export interface ImportCsvProps {
  resourceName: string;
  columns: CsvColumn[];
  sampleData: Array<any>;
  fileImportedCallback(errorFound: boolean, file: any, count: number);
  fileBrowseCallback();
  reset?: boolean;
  importErrorList?: string[];
  importErrorMsg?: string;
  rowValidator?: Function;
  maxLimit?: number;
}

const ImportCsv = (props: ImportCsvProps) => {
  const [error, setError] = useState('');
  const [errorList, setErrorList] = useState([]);
  const [file, setFile] = useState<any>();
  const [parsing, setParsing] = useState<boolean>();
  const [errorMsg, setErrorMsg] = useState('csv_error_msg');
  const { t } = useTranslation();

  useEffect(() => {
    setFile(undefined);
  }, [props.reset]);

  useEffect(() => {
    if (props.importErrorList && props.importErrorList.length) {
      setErrorList(props.importErrorList);
      setErrorMsg(props.importErrorMsg);
    }
  }, [props.importErrorList, props.importErrorMsg]);

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setParsing(true);
    setError('');
    setFile(undefined);
    setErrorList([]);
    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError('File extension must be .csv.');
        setParsing(false);
        return;
      }
      if (inputFile.size / 1024 > 1024.0) {
        setError('file_size_exceed_msg');
        setParsing(false);
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
      handleParse(inputFile);
    }
  };

  const downloadSampleCSV = () => {
    createAndDownloadCSV(
      props.sampleData,
      `sample-${props.resourceName}.csv`,
      true
    );
  };

  const handleParse = (csvFile: any) => {
    let errors = [];
    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
        skipEmptyLines: true,
      });
      const parsedData = csv?.data;
      let errorFound = false;
      let colMaps = {};
      let fieldsObj = {};
      for (const f of csv.meta.fields) {
        fieldsObj[f] = 1;
      }
      for (const iterator of props.columns) {
        if (!fieldsObj[iterator.displayName] && iterator.checkEmptyValue) {
          setError(`Line 1: Header is missing`);
          setParsing(false);
          return;
        }
      }
      if (!parsedData || parsedData.length === 0) {
        setError('There is no record in CSV');
        setParsing(false);
        return;
      }
      let maxLimit = props.maxLimit ? props.maxLimit : 2000;
      if (parsedData.length > maxLimit) {
        setError(t('import_max_row_exceeded', { limit: maxLimit }));
        setParsing(false);
        return;
      }
      for (let index = 0; index < parsedData.length; index++) {
        const element = parsedData[index];
        if (element['__parsed_extra']) {
          errorFound = true;
          errors.push(
            t('csv_extra_data_error', { index: (index + 2).toString() })
          );
          if (errors.length === 11) {
            // Stop after 10 errors
            errors.pop();
            break;
          }
          continue;
        }

        for (const iterator of props.columns) {
          if (!colMaps[iterator.displayName]) {
            colMaps[iterator.displayName] = {};
          }
          const val = element[iterator.displayName];
          if (!val && iterator.checkEmptyValue) {
            errorFound = true;
            errors.push(
              t('csv_entry_missing_error', {
                displayName: iterator.displayName,
                index: (index + 2).toString(),
              })
            );
          } else if (val !== undefined) {
            if (val) {
              let isValid = true;
              switch (iterator.type) {
                case 'mac':
                  isValid = isValidMACAddress(val);
                  break;
                case 'ip':
                  isValid = isIPV4Valid(val) || isIPV6Valid(val);
                  break;
                case 'vendor':
                  const vendorErr = getVendorError(val);
                  if (vendorErr) {
                    errorFound = true;
                    errors.push(
                      t('csv_entry_' + vendorErr, {
                        displayName: iterator.displayName,
                        index: (index + 2).toString(),
                        val: val,
                      })
                    );
                  }
                  break;
                case 'number':
                  isValid = isNumeric(val);
                  break;
                case 'email':
                  isValid = isEmailValid(val);
                  break;
                case 'phone':
                  const phoneErr = validatePhoneNumber(val);
                  if (phoneErr) {
                    errorFound = true;
                    errors.push(
                      t('csv_error_' + phoneErr, {
                        index: (index + 2).toString(),
                      })
                    );
                  }
                  break;
              }
              if (!isValid) {
                errorFound = true;
                errors.push(
                  t('csv_entry_invalid_error', {
                    displayName: iterator.displayName,
                    index: (index + 2).toString(),
                    val: val,
                  })
                );
              }
              if (
                !errorFound &&
                iterator.uniqueValue &&
                colMaps[iterator.displayName][val]
              ) {
                errorFound = true;
                errors.push(
                  t('csv_entry_duplicate_error', {
                    displayName: iterator.displayName,
                    index: (index + 2).toString(),
                    val: val,
                  })
                );
              }
              colMaps[iterator.displayName][val] = 1;
            }
            if (errorFound) {
              if (errors.length === 11) {
                errors.pop();
                break;
              }
            }
          }
        }

        if (props.rowValidator) {
          const rowError = props.rowValidator(element, props.columns);
          if (rowError) {
            errorFound = true;
            errors.push(
              t('csv_extra_row_error', {
                index: (index + 2).toString(),
                rowError: t(rowError),
              })
            );
          }
        }
      }
      setErrorList(errors);
      if (errors.length < 10) {
        setErrorMsg('csv_error_msg');
      } else if (errors.length > 10) {
        setErrorMsg('csv_error_many');
      }
      setParsing(false);
      props.fileImportedCallback(errorFound, csvFile, parsedData.length);
    };
    reader.readAsText(csvFile);
  };

  const resetFile = (e) => {
    e.target.value = null;

    setError('');
    setFile(undefined);
    setErrorList([]);
    props.fileBrowseCallback();
  };

  return (
    <Box>
      <TextField
        id="id_csv_upload"
        fullWidth
        label={t('upload_csv_file')}
        helperText={t(error)}
        error={error !== ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button
                id="id_button_browse"
                variant="outlined"
                component="label"
                color="primary"
                size="small"
                sx={{ borderRadius: 0.5 }}
              >
                {t('Browse')}
                <input
                  type="file"
                  hidden
                  onChange={(event) => handleFileChange(event)}
                  onClick={(event) => {
                    resetFile(event);
                  }}
                />
              </Button>
              {file && <Typography sx={{ pl: 1 }}>{file.name}</Typography>}
            </InputAdornment>
          ),
        }}
      />

      <Grid container alignItems={'center'} sx={{ pt: 1 }}>
        <Grid item>
          <Typography variant="subtitle1">{t('Columns')}:&nbsp;</Typography>
        </Grid>
        {props.columns.map((val, index) => (
          <Grid item key={index}>
            <span>{val.displayName}</span>
            {val.checkEmptyValue && <span style={{ color: 'red' }}>*</span>}
            {index !== props.columns.length - 1 && <span>,&nbsp;</span>}
          </Grid>
        ))}

        <Grid item sx={{ marginLeft: 'auto', marginTop: '16px' }}>
          <Button
            onClick={downloadSampleCSV}
            size="small"
            variant="outlined"
            sx={{ borderRadius: 0.5, py: 0.5 }}
            endIcon={<DownloadIcon />}
          >
            {t('Sample')}
          </Button>
        </Grid>
      </Grid>
      {errorList.length > 0 && (
        <Card sx={{ p: 1, mt: 1, border: '1px solid lightgray' }}>
          <Box sx={{ pb: 1 }}>
            <Typography color={'error'} variant="h5">
              {t(errorMsg)}
            </Typography>
          </Box>
          {errorList.map((e, i) => (
            <Fragment key={i}>
              <Divider />
              <Box sx={{ p: 0.5 }}>{e}</Box>
            </Fragment>
          ))}
          {errorList.length >= 10 && (
            <>
              <Divider />
              <Box sx={{ pl: 0.5, pt: 1 }}>
                <Typography color={'info'} variant="h5">
                  {t('reimport_csv_msg')}
                </Typography>
              </Box>
            </>
          )}
        </Card>
      )}
      {parsing && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

export default ImportCsv;
